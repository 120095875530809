import { t } from "i18next";
import React, { useMemo } from "react";

export const ReferralRemark = (props: any) => {
  const { texts } = props;
  const promotion = useMemo(() => {
    return texts.map((text: any, index: any) => {
      return (
        <p key={`benefit${index}`} className="mt-2 font-bold	text-[#6F6F6F]">
          {t(`${index + 1}.${text}`)}
        </p>
      );
    });
  }, [texts]);
  return (
    <div className="mt-10">
      <h4 className="font-bold text-[12px] tracking-widest text-center text-at-pink">
        {t("referral.subtitle")}
      </h4>
      <div className="px-10">{promotion}</div>
    </div>
  );
};
