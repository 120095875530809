import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { history } from "src/stores";
import { TnC } from "./tnc";
import { HowToUse } from "./how-to-use";
import { AvailableOutlets } from "./available-outlets";
import { Info } from "./info";
import { openNotificationWithIcon } from "../notification";
import { PaymentOptions } from "./payment-options";
import i18n from "src/i18n";

export const FullTicket = (props: any) => {
  const language = i18n.language;
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const { data, from, myLinkPoint } = props;
  const {
    couponTitle,
    couponTitleEn,
    couponIcon = "/assets/AT_logo.png",
    amount,
    atPoints,
    expirationDate,
    availableOutlets = [],
    instructions = [],
    tnc = [],
  } = data;

  const getBtnName = () => {
    switch (from) {
      case "myCoupon":
        return "ticket.use";
      default:
        return "ticket.get";
    }
  };

  const purchaseCoupon = () => {
    if (!getPaymentOptions || getPaymentOptions.length === 0) {
      return openNotificationWithIcon(
        "info",
        t("messages.comingSoon"),
        t("messages.comingSoon")
      );
    }

    return setOpenPayment(true);
  };

  const getPaymentOptions = useMemo(() => {
    const { amount, atPoints } = data;
    let paymentOptions: any = [];
    if (amount) {
      // TODO add card - paymentOptions = [...paymentOptions, "card"];
    }
    if (atPoints) paymentOptions = [...paymentOptions, "at_points"];
    if (myLinkPoint > -1) paymentOptions = [...paymentOptions, "myLink_points"];

    return paymentOptions;
  }, [data, myLinkPoint]);

  const title = useMemo(() => {
    if (language === "en") return couponTitleEn || couponTitle;
    return couponTitle;
  }, [language, couponTitle, couponTitleEn]);

  return (
    <div className="relative w-full bg-white flex flex-col px-5 py-5 items-center rounded-2xl border-[1px]">
      <div className="w-14 h-14 rounded-full overflow-hidden">
        <img
          src={couponIcon}
          alt={couponTitle}
          className=" w-full h-full object-contain"
        />
      </div>

      <Info
        couponTitle={title}
        amount={amount}
        atPoints={atPoints}
        expirationDate={expirationDate}
      />

      <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />

      {/* Place to use */}
      <AvailableOutlets data={availableOutlets} />

      <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />

      {/* How to use */}
      <HowToUse data={instructions} />

      <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />

      {/* T & C */}
      <TnC data={tnc} />

      {/* Get button */}
      <div
        className="fixed top-[90%] px-8 w-screen"
        onClick={
          from === "myCoupon"
            ? () => history.push("/use-coupon", { coupon: data })
            : () => purchaseCoupon()
        }
      >
        <button className="full py-3 text-xs">{t(getBtnName())}</button>
      </div>

      {/* Bottom sheet - Show payment options */}
      <PaymentOptions
        open={openPayment}
        setOpen={setOpenPayment}
        options={getPaymentOptions}
        coupon={data}
        myLinkPoint={myLinkPoint}
      />

      {/* Left circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[100px] left-[-10px] overflow-hidden" />

      {/* Right circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[100px] right-[-10px] overflow-hidden" />
    </div>
  );
};
