import React, { useMemo } from "react";
import { t } from "i18next";
import { CustomModal } from "src/components";
import { CreditCardOutlined } from "@ant-design/icons";

// const footerStyle = "text-[10px] w-auto px-[20px] mx-1 py-[3px] text-base";

export const PendingOrderModal = (props: any) => {
  const {
    open,
    title = "",
    buttonHandler,
    pendingOrder,
    maskClosable = false,
    closable = false,
    onCancel = {},
  } = props;

  const bodyMessage = useMemo(() => {
    if (!pendingOrder || !pendingOrder["msg"])
      return t("modal.pendingTransactionAmount", {
        amount: 0,
      });
    switch (pendingOrder["msg"]) {
      case "pending_order_required":
        return t("modal.pendingTransactionAmount", {
          amount: pendingOrder.data.amount,
        });
      case "pending_order_completed":
        return t("modal.pendingTransactionCompleted", {
          amount: pendingOrder.data.amount,
        });
      default:
        break;
    }
  }, [pendingOrder]);

  const getBtnTxt = (type: string, penderOrder: any) => {
    if (!penderOrder) {
      if (type === "top") return "";
      if (type === "button") return "";
    }

    switch (pendingOrder["msg"]) {
      case "pending_order_required":
        if (type === "top") return "goToTopup";
        if (type === "button") return "cancel";
        break;
      case "pending_order_completed":
        if (type === "top") return "confirm";
        if (type === "button") return "back";
        break;
      default:
        return "";
    }
  };

  const handleConfirm = () => {
    buttonHandler(pendingOrder["msg"], pendingOrder);
  };
  const handleCancel = () => {
    buttonHandler(pendingOrder["msg"], pendingOrder, "cancel");
  };

  return (
    <>
      {pendingOrder["msg"] && (
        <CustomModal
          className="pending-order-modal"
          open={open}
          centered
          destroyOnClose
          title={title}
          maskClosable={maskClosable}
          closable={closable}
          okText={t(`button.confirm`)}
          onCancel={onCancel}
          topButtonTxt={getBtnTxt("top", pendingOrder)}
          bottomButtonTxt={getBtnTxt("button", pendingOrder)}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        >
          <CreditCardOutlined className="modal-icon" />
          <div className="px-[30px] py-[10px] flex justify-center max-w-[400px] min-w-[320px] text-center">
            <p>{bodyMessage}</p>
          </div>
        </CustomModal>
      )}
    </>
  );
};
