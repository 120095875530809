import { MINTING_URL } from "src/config";

export const SAMPLE_EVENTS = [
  {
    name: "",
    imageUrl: "/assets/SC.png",
    url: "https://slashsim.hk/web/slash/home?utm_source=AsiaTop&utm_medium=topbanner",
  },
  {
    name: "events.atghnft",
    imageUrl: "/assets/13.png",
    url: "https://atgh-mint.asiatop.space/",
  },
  {
    name: "events.nft",
    imageUrl: "/assets/at-bird-pink.png",
    url: MINTING_URL,
  },
];
