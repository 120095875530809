import React from "react";
import moment from "moment";
import { history } from "src/stores";

export const MovieList = (props: any) => {
  const { movies = [], location, hasMovie } = props;

  return (
    <>
      {hasMovie &&
        movies.map((item: any, i: number) => {
          return (
            <div
              key={i}
              className="rounded-2xl relative last:pb-0 mr-2 bg-white px-1 pb-2 max-w-[160px]"
            >
              <div
                className="relative items-center w-[160px] h-[260px]"
                onClick={() =>
                  history.push("/movie-detail", {
                    param: { filmId: item.id },
                    prevPath: location.pathname,
                  })
                }
              >
                <img
                  src={item.image}
                  className="w-full h-full object-cover"
                  alt={item.id}
                />
              </div>
              <div className="w-full font-semibold mt-3 truncate">
                {item.title}
              </div>
              <div className="w-full text-slate-400 font-medium">
                {moment(item.start_date).format("yyyy年MM月DD日")}
              </div>
            </div>
          );
        })}
    </>
  );
};
