import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { errorHandling, isResultSuccess, setHeader } from "./api";

export class CinemaStore {
  @action
  async getCinemaMovieList(payload: any) {
    const url = `${Configs.apiUrl}/cinema/movie-list`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async getCinemaMovieDetail(payload: any) {
    const url = `${Configs.apiUrl}/cinema/movie-detail`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async getMovieShowingDateList(payload: any) {
    const url = `${Configs.apiUrl}/cinema/movie-show-date`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async getMovieShowingCinemaList(payload: any) {
    const url = `${Configs.apiUrl}/cinema/movie-showing-cinema-list`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async getMovieShowingDetail(payload: any) {
    const url = `${Configs.apiUrl}/cinema/movie-showing-detail`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_CINEMA = "cinemaStore";
