import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { history } from "src/stores";
import { MovieList } from "../movie/movie-list";
import { LoadingIcon } from "src/components";

export const MovieIndex = (props: any) => {
  const { movies, loading, location } = props;
  const { t } = useTranslation();

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  const hasMovie = useMemo(() => {
    return movies && movies.length > 0;
  }, [movies]);

  return (
    <div className="bg-white drop-shadow-md overflow-hidden overflow-x-scroll scroll-px-5 snap-x">
      <div className="flex flex-row px-5 pt-5">
        <h5 className="flex flex-1">{t("newMovies")}</h5>
        <button onClick={() => history.push("/movie-list")}>
          {t("checkOut")}
          {t("all")}
        </button>
      </div>
      <div className="mt-1 bg-transparent p-5 overflow-hidden overflow-x-scroll scroll-px-5 snap-x scroll-smooth">
        <div className="flex flex-row flex-nowrap">
          {isLoading && (
            <div className="flex justify-center flex-1 py-5">
              <LoadingIcon />
            </div>
          )}

          {!hasMovie && !isLoading && (
            <div className="flex justify-center flex-1 py-5">
              <p>{t("noMovieFound")}</p>
            </div>
          )}

          <MovieList
            movies={movies}
            hasMovie={hasMovie}
            loading={loading}
            location={location}
          />
        </div>
      </div>
    </div>
  );
};
