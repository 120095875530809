import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, openWarningNotification } from "src/components";
import { AtPointBalance } from "./atPoint-balance";
import { TopUpMethods } from "./top-up-methods";
import { ConfirmTopupModal } from "./confirm-topup-modal";

export const TopUpAtPoints = (props: any) => {
  const { t } = useTranslation();
  const { location } = props;
  const { topUpAmount = 0, balance = 0, required = 0 } = location.state || {};

  const [topupAtpAmount, setTopupAtpAmount] = useState(required);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<string>("fps");

  useEffect(() => {
    if (!topUpAmount) return;
    setTopupAtpAmount(topUpAmount);
  }, [topUpAmount, setTopupAtpAmount]);

  const handleTopUp = () => {
    if (!topupAtpAmount || topupAtpAmount === 0)
      return openWarningNotification(t("messages.pleaseEnterTopupAmount"));
    // Open Confirm Modal
    setConfirmOpen(true);
  };

  return (
    <Container toPage={"/"} title={t("topupAtpoints")} showExchange={false}>
      {/* AT Balance */}
      <AtPointBalance
        balance={balance}
        topupAtpAmount={topupAtpAmount}
        setTopupAtpAmount={setTopupAtpAmount}
      />

      {/* Top Up Method */}
      <TopUpMethods
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />

      <div className="fixed w-full px-8 bottom-[30px]">
        <button
          className="primary text-[12px] full py-3"
          onClick={() => handleTopUp()}
        >
          {t("topupNow")}
        </button>
      </div>

      {/* Confirm Modal */}
      <ConfirmTopupModal
        balance={balance || 0}
        topupAtpAmount={topupAtpAmount}
        selectedPayment={selectedPayment}
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
      />
    </Container>
  );
};
