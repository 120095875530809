import { t } from "i18next";
import React, { useEffect } from "react";
import { GetToken } from "src/utils/token";

export const MyPoint = (props: any) => {
  const { list, user } = props;

  useEffect(() => {}, [user]);

  const hsaMyLink = () => {
    const myLinkToken = GetToken("my-link-token");
    return myLinkToken;
  };

  return (
    <div className="bg-white drop-shadow-md pb-5 px-5 pt-5 relative">
      <p className="text-pink font-semibold pb-3">{t("myPoints")}</p>
      {list.map((v: any, i: number) => {
        if (v.type === "MyLink" && !hsaMyLink()) return null;
        return (
          <div className="px-3 py-3 flex flex-row" key={i}>
            <div className="w-1/3 flex flex-row items-center">
              <div className="w-4">
                <img src={v.img} alt={v.name} />
              </div>
              <div className="w-2/3 font-semibold text-center">{v.name}</div>
            </div>

            <div className="w-2/3 font-semibold text-right">
              {v.type === "AT" ? user.at_points || 0 : null}
              {v.type === "MyLink" ? user.myLinkPoint || 0 : null}
              <span className="ml-2">{v.unit}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
