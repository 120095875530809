import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { Form } from "./form";

export const WalletEditProfile = (props: any) => {
  const { t } = useTranslation();
  const { location } = props;
  const { user } = location.state || {};
  return (
    <Container toPage={"/wallet"} title={t("settings")}>
      <div className="px-5 pt-10">
        <Form user={user} />
      </div>
    </Container>
  );
};
