import React from "react";
import { Container, openWarningNotification } from "../../components";
import { useTranslation } from "react-i18next";
import { useTransactionStore } from "src/stores";
import QrReader from "react-web-qr-reader";

const DELAY = 1000;
export const ServiceUse = (props: any) => {
  const { payToMerchant } = useTransactionStore();
  const { t } = useTranslation();
  const { location } = props;
  const cardNumber = (location.state && location.state.cardNumber) || null;

  // Testing - Do not delete
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleScan({
  //       data: "00020101021226360012hk.com.hkicl01039300209799084959520400005303344540413.65802HK5902NA6002HK62280124000000000004000002301939630497A7",
  //     });
  //   }, 5000);
  // }, []);

  const handleScan = async (value: any) => {
    const data = value.data;
    if (!data) return;

    // Pay to Merchant
    const payload = {
      card_no: cardNumber,
      qrc_data: data,
      return_url: `${window.location.origin}/service`,
      card_service: "gc",
    };
    const transaction = await payToMerchant(payload);
    if (!transaction) return openWarningNotification(t("messages.cannotPay"));

    const url = transaction["response_detail"]["redirect_url"];
    if (!url) return openWarningNotification(t("messages.cannotPay"));
    window.open(url, "_self");
  };

  const handleError = (error: any) => {
    console.log("Error in handling QR Scan", error);
  };

  if (!QrReader) return null;
  return (
    <Container hasFooter={false} title={`${t("service")}`} toPage={`/service`}>
      {/* Scanner */}
      <div className="w-full bg-white flex flex-col mt-5 flex-1 bg-black h-full absolute top-[0px] left-0 justify-center">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <QrReader
          facingMode="environment"
          onError={(e: any) => handleError(e)}
          onScan={(e: any) => handleScan(e)}
          className="qr-reader"
          style={{ width: "100%" }}
          delay={DELAY}
        />
      </div>
    </Container>
  );
};
