import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { useCinemaStore } from "src/stores";
import { openNotificationWithIcon } from "src/components";
import { EnvironmentOutlined } from "@ant-design/icons";
import { history } from "src/stores";
import moment from "moment";
import { LoadingIcon } from "src/components";

export const MovieShowingDetail = (props: any) => {
  const { location } = props;
  const { param } = location.state;
  const [loading, setLoading] = useState(true);
  const { getMovieShowingDetail } = useCinemaStore();
  const { t } = useTranslation();
  const [showingDetail, setShowingDetail] = useState<any>([]);
  const [cinemaDetail, setCinemaDetail] = useState<any>([]);
  const [showingType, setShowingType] = useState<any>([]);
  const [seatImg, setSeatImg] = useState<string>("");
  const [buyTicketsUrl, setBuyTicketsUrl] = useState<string>("");
  useEffect(() => {
    setLoading(true);
    getMovieShowingDetail(param).then((result) => {
      setLoading(false);
      setSeatImg(result.link);
      setShowingType(result.filmType);
      setCinemaDetail(result.cinemaDetail);
      setShowingDetail(result.filmDetail);
      setBuyTicketsUrl(result.seatPreviewUrl);
    });
  }, [getMovieShowingDetail, param]);

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  return (
    <Container
      toPage={() => history.goBack()}
      title={showingDetail.title ? showingDetail.title : t("backUp")}
    >
      {isLoading && (
        <div className="flex justify-center pt-5 absolute w-full pb-12 top-72">
          <LoadingIcon />
        </div>
      )}
      {showingDetail && !isLoading && (
        <>
          <div className="bg-white drop-shadow-md p-5 overflow-x-scroll scroll-px-5 snap-x">
            <div className="grid grid-cols-2 gap-2">
              <div className="row-span-6">
                <img
                  src={showingDetail.image}
                  className="w-full  object-cover"
                  alt="img"
                />
              </div>
              <div className="row-span-5 ml-3 leading-4">
                {showingType &&
                  showingType.map((item: any, i: number) => {
                    return (
                      <button key={i} className="p-px rounded-full">
                        <span className="block text-xs px-2 py-0.5 rounded-full bg-white text-pink overflow-hidden max-h-5">
                          {item.name}
                        </span>
                      </button>
                    );
                  })}
                <div className="text-slate-400 text-xs mt-2">
                  {showingDetail.level},{showingDetail.duration}
                  {t("minute")}
                </div>
              </div>
              <div className="flex flex-col-reverse">
                <p className="col-span-1 ml-3 font-medium text-slate-400">
                  {moment(showingDetail.time).format("yyyy年MM月DD日")}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white drop-shadow-md p-5 mt-3">
            <div className="grid grid-cols-6 gap-5 ml-3.5">
              <h5 className="col-span-4 text-base text-pink">
                {cinemaDetail.name}
              </h5>
              {/* <p className="col-span-2 text-xs text-black text-right">{t('moviePrice')} $132</p> */}
            </div>
            <div className="text-xs mt-1 ml-3.5">
              <EnvironmentOutlined />
              <span className="ml-1 leading-4">{cinemaDetail.address}</span>
            </div>
          </div>
          <div className="p-5">
            <iframe
              referrerPolicy="unsafe-url"
              title="seat"
              id="seat-frame"
              src={seatImg}
              style={{
                width: "100%",
                maxWidth: "400px",
                marginLeft: "auto",
                marginRight: "auto",
                height: "100%",
                minHeight: "450px",
                background: "#000",
              }}
              sandbox="allow-same-origin allow-scripts"
            ></iframe>
          </div>
          <div className="px-8 flex w-full">
            <button
              className="full py-3 text-xs"
              onClick={
                buyTicketsUrl
                  ? () =>
                      window.open(
                        `${buyTicketsUrl + "&film_show_id=" + param.filmShowId}`
                      )
                  : () =>
                      openNotificationWithIcon(
                        "info",
                        t("messages.warning"),
                        t("messages.comingSoon")
                      )
              }
            >
              {t("buyTicket")}
            </button>
          </div>
        </>
      )}
    </Container>
  );
};
