// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { ApplicationStore, STORE_APPLICATION } from "./application";
import { AuthStore, STORE_AUTH } from "./auth";
import { CardStore, STORE_CARD } from "./card";
import { CouponStore, STORE_COUPON } from "./coupon";
import { MemberStore, STORE_MEMBER } from "./member";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { STORE_TRANSACTION, TransactionStore } from "./transaction";
import { STORE_CINEMA, CinemaStore } from "./cinema";
import { STORE_REFERRAL, ReferralStore } from "./referral";
import { STORE_MYLINK, MyLinkStore } from "./myLink";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_COUPON]: new CouponStore(),
    [STORE_MEMBER]: new MemberStore(),
    [STORE_TRANSACTION]: new TransactionStore(),
    [STORE_CINEMA]: new CinemaStore(),
    [STORE_APPLICATION]: new ApplicationStore(),
    [STORE_CARD]: new CardStore(),
    [STORE_REFERRAL]: new ReferralStore(),
    [STORE_MYLINK]: new MyLinkStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useCouponStore() {
  const { couponStore } = useStores();
  return couponStore;
}

export function useMemberStore() {
  const { memberStore } = useStores();
  return memberStore;
}

export function useTransactionStore() {
  const { transactionStore } = useStores();
  return transactionStore;
}

export function useCinemaStore() {
  const { cinemaStore } = useStores();
  return cinemaStore;
}

export function useApplicationStore() {
  const { applicationStore } = useStores();
  return applicationStore;
}

export function useCardStore() {
  const { cardStore } = useStores();
  return cardStore;
}

export function useReferralStore() {
  const { referralStore } = useStores();
  return referralStore;
}

export function useMyLinkStore() {
  const { myLinkStore } = useStores();
  return myLinkStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
