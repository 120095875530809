import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useCouponStore, useMemberStore } from "src/stores";
import { BottomModal } from "../bottomModal";
import { history } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
  openErrNotification,
} from "../notification";
import { LoadingIcon } from "../";

const { Radio } = require("antd");

export const PaymentOptions = (props: any) => {
  const { getUserMembershipInfo } = useMemberStore();
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<string>("at_points");
  const { issueCoupon, issueMezzofyCoupon } = useCouponStore();

  const { setOpen, open, options, coupon, myLinkPoint } = props;
  const { couponIcon, couponTitle, atPoints, couponCode, couponId, type } =
    coupon;

  useEffect(() => {
    getUserMembershipInfo().then((result) => {
      if (result) return setMemberInfo(result);
    });
  }, [getUserMembershipInfo, setMemberInfo]);

  // Config
  const getPaymentName = (item: string) => {
    switch (item) {
      case "at_points":
        return `AT ${t("points")}`;

      case "myLink_points":
        return `MyLink ${t("points")}`;
    }
  };

  // Get user balance
  const checkBalance = (item: string, balance: string) => {
    switch (item) {
      case "at_points":
        if (parseFloat(balance) >= parseFloat(coupon["atPoints"])) return true;
    }
    return false;
  };

  const getBalance = (item: string) => {
    switch (item) {
      case "at_points":
        const atPoints = memberInfo["at_points"] || 0;
        return atPoints;

      case "myLink_points":
        const myLink_points = myLinkPoint || 0;
        return myLink_points;
    }
  };

  const getPaymentBtn = (item: string) => {
    const balance = getBalance(item);

    if (item === "myLink_points") {
      return t("exchangePoints");
    }
    const isEnoughBalance = checkBalance(item, balance);
    if (!isEnoughBalance) return t("topup");
    return t("pay");
  };

  const getRequiredAmount = (item: string, balance: string) => {
    switch (item) {
      case "at_points":
        return String(parseFloat(coupon["atPoints"]) - parseFloat(balance));
    }
    return 0;
  };

  const paymentAction = async (item: string) => {
    setLoading(true);
    const isEnoughBalance = checkBalance(item, memberInfo["at_points"]);
    const priceDiff = getRequiredAmount("at_points", memberInfo["at_points"]);
    if (!isEnoughBalance) {
      if (item === "myLink_points") {
        openWarningNotification("messages.mylinkPointInsufficient");
        return history.push("/exchange", { coupon });
      }
      return history.push("/top-up-atpoints", {
        topUpAmount: priceDiff,
        balance: memberInfo["at_points"],
      });
    }
    if (!type) {
      const isIssued = await issueCoupon({
        coupon_id: coupon["id"],
        pay_with: selectedPayment,
      });
      if (!isIssued) {
        history.push("/");
        return openErrNotification("messages.failedToPurchaseCoupon");
      }
    }

    if (type && type === "mezzofy") {
      const isMezzofyCouponIssued = await issueMezzofyCoupon({
        logo: couponIcon,
        name: couponTitle,
        coupon_id: couponId,
        coupon_code: couponCode,
        at_points: atPoints,
        method: "mezzofy",
      });
      if (!isMezzofyCouponIssued) {
        history.push("/");
        return openErrNotification("messages.failedToPurchaseCoupon");
      }
    }

    setLoading(false);
    openSuccessNotification(t("messages.successfullyPurchasedCoupon"));
    return history.push("/coupon-purchased", { coupon });
  };

  if (!options || options.length === 0) return null;

  return (
    <BottomModal setOpen={setOpen} open={open}>
      <div className="p-5 text-center mx-auto min-h-[300px] flex flex-col">
        <h5 className="pb-[15px]">{t("paymentOptions")}</h5>
        {/* Payment Options */}
        <Radio.Group
          className="flex flex-col flex-1 justify-start"
          value={selectedPayment}
          onChange={(e: any) => setSelectedPayment(e.target.value)}
        >
          {options.map((item: any, i: any) => {
            return (
              <Radio
                className="flex flex-row-reverse pb-3 justify-start items-center"
                value={item}
                key={i}
              >
                <div className="flex flex-row w-[80vw] text-left">
                  <p className="w-[100%]">{getPaymentName(item)}</p>
                  <p className="text-black font-bold mr-3">
                    {getBalance(item)}
                  </p>
                </div>
              </Radio>
            );
          })}
        </Radio.Group>

        {/* Button */}
        <div className="pt-10 flex justify-center">
          {loading ? (
            <div>
              <LoadingIcon />
            </div>
          ) : (
            <button
              className="primary full py-3"
              onClick={() => paymentAction(selectedPayment)}
            >
              {getPaymentBtn(selectedPayment)}
            </button>
          )}
        </div>
      </div>
    </BottomModal>
  );
};
