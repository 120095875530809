import React from "react";
import * as Configs from "../../config";

export const MyLinkEntrance = (props: any) => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  let myLinkToken = params.thirdPartyToken;

  const redirect = (type: string) => {
    if (!myLinkToken) return;
    let url = null;
    const platform = Configs.PLATFORM;
    switch (type) {
      case "at":
        if (platform === "dev") url = "https://staging.asiatop.space";
        if (platform === "prod") url = "https://asiatop.space";
        break;
      case "atb":
        if (platform === "dev") url = "https://test.asiatop.net/atb";
        if (platform === "prod") url = "https://atb.asiatop.net";
        break;
      default:
        break;
    }
    if (!url) return;
    window.open(`${url}?thirdPartyToken=${myLinkToken}`, "_self");
  };

  return (
    <div
      className="bg-cover bg-center h-screen w-screen p-[20px]"
      style={{
        backgroundImage: "url('/assets/mylink-bg.jpg')",
      }}
    >
      <div className="bg-white/100 w-full h-full rounded-[20px] p-[30px] relative max-w-[600px] mx-auto">
        {/* Title */}
        <h1 className="tracking-[0px] leading-[40px] relative text-[30px] font-[900]">
          AT 聚分鳥
        </h1>
        {/* North */}
        <div className={`${$rowStyle} mt-[30px] justify-start`}>
          <img src="/assets/north.png" className={$rowImgStyle} alt="north" />
          <div className={`${$rowColStyle}`}>
            <h1 className={`${$rowColH1Style} left-[-30px]`}>北上消費</h1>
            <h2 className={`${$rowColH2Style} ml-[5px]`}>
              隨心以人民幣充值 ATP
            </h2>
            <button className={$rowColBtnStyle} onClick={() => redirect("atb")}>
              立即前往
            </button>
          </div>
        </div>
        {/* South */}
        <div className={`${$rowStyle} mt-[30px] justify-end`}>
          <div className={`${$rowColStyle}`}>
            <h1 className={`${$rowColH1Style} right-[-30px]`}>南下遊玩</h1>
            <h2 className={`${$rowColH2Style} mr-[5px]`}>盡情以港幣使用 AT</h2>
            <button className={$rowColBtnStyle} onClick={() => redirect("at")}>
              立即前往
            </button>
          </div>
          <img src="/assets/south.png" className={$rowImgStyle} alt="south" />
        </div>

        {/* AT Bird */}
        <img
          src="/assets/at-bird-pink.png"
          alt="at-bird"
          className="absolute bottom-[0px] right-[-30px] w-[400px] z-[0px]"
        />
      </div>
    </div>
  );
};

const $rowStyle = "flex flex-row  items-center relative z-[10px]";
const $rowImgStyle = "w-[50%]";
const $rowColStyle = "flex flex-col";
const $rowColH1Style =
  "tracking-[0px] leading-[40px] relative  text-black text-[30px] font-[900]";
const $rowColH2Style =
  "tracking-[0px] text-black text-[12px] font-[700] mt-[10px] ml-[5px]";
const $rowColBtnStyle = "gradient py-[5px] mt-[10px]";
