import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { isResultSuccess, refreshNewToken, setHeader } from "./api";

export class ApplicationStore {
  @action
  async checkForm(args: any) {
    const url = `${Configs.apiUrl}/application/check-byon${args ? args : null}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return null;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return null;
    }
  }
}

export const STORE_APPLICATION = "applicationStore";
