import { t } from "i18next";
import React from "react";
import moment from "moment";

export const Info = (props: any) => {
  const { couponTitle, amount, atPoints, expirationDate } = props;

  return (
    <div className="text-center pt-3">
      <p>{couponTitle}</p>
      <div className="flex flex-row justify-center py-[1px] items-end">
        {amount && (
          <p className="text-black text-xl tracking-tight">${amount}</p>
        )}
        {amount && atPoints && (
          <p className="px-2 relative top-[-3px]">{t("or")}</p>
        )}
        {atPoints && (
          <p className="text-black text-xl tracking-tight rainbow">
            {atPoints} <span className="text-sm text-black">AT</span>
          </p>
        )}
      </div>

      {expirationDate && (
        <p className="text-[9px] text-slate-400 pt-3">
          {t("expiration")}: {moment(expirationDate).format("Do MMMM YYYY")}
        </p>
      )}
    </div>
  );
};
