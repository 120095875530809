import { t } from "i18next";
import React, { useState } from "react";
import moment from "moment";
import { useMemberStore } from "src/stores";
import { openNotificationWithIcon } from "src/components";
import { history } from "src/stores";
const { DatePicker } = require("antd");

const labelClass = " block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

const defaultDate = "01-01-1990";
export const Form = (props: any) => {
  const { user } = props;
  const { nickname = "", email = "", dob = defaultDate } = user;
  const [newNickName, setNewNickName] = useState<string>(nickname);
  const [newEmail, setNewEmail] = useState<string>(email);
  const [newDob, setNewDob] = useState<string>(dob);
  const { updateUserInfo } = useMemberStore();

  const handleChange = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "nickname") return setNewNickName(value);
    if (name === "email") return setNewEmail(value);
  };

  const handleDob = (date: any, dateString: any) => {
    if (!date) return setNewDob(defaultDate);
    const formattedDate = moment(dateString).format("MM-DD-YYYY");
    setNewDob(formattedDate);
  };

  const submitForm = async () => {
    const payload = {
      ...(newNickName && { nickname: newNickName }),
      ...(newEmail && { email: newEmail }),
      ...(newDob && { dob: newDob }),
    };
    const isUpdate = await updateUserInfo(payload);
    if (!isUpdate) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.comingSoon")
      );
    }

    openNotificationWithIcon(
      "success",
      t("messages.congraz"),
      t("messages.updateUserSuccess")
    );
    return history.push("/wallet");
  };

  return (
    <div>
      <label className="block">
        <span className={labelClass}>{t("form.name")}</span>
        <input
          type="text"
          name="nickname"
          className={inputClass}
          value={newNickName}
          onChange={handleChange}
        />
      </label>

      <label className="block mt-5">
        <span className={labelClass}>{t("form.email")}</span>
        <input
          type="email"
          name="email"
          className={inputClass}
          placeholder="example@gmail.com"
          value={newEmail}
          onChange={handleChange}
        />
      </label>

      <label className="block mt-5">
        <span className={labelClass}>{t("form.dob")}</span>
        <DatePicker
          onChange={handleDob}
          picker="date"
          placeholder=""
          defaultPickerValue={moment(defaultDate,"MM-DD-YYYY")}
          className={inputClass}
          value={newDob ? moment(newDob,"MM-DD-YYYY") : moment(defaultDate,"MM-DD-YYYY")}
          style={{
            borderRadius: 5,
            marginTop: 5,
            fontSize: 12,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        />
      </label>

      <button className="full mt-10 py-3 text-xs" onClick={() => submitForm()}>
        {t("form.save")}
      </button>
    </div>
  );
};
