import React from "react";
import { OPTIONS, getName } from "src/utils";
const { Radio } = require("antd");

export const PaymentMethodOptions = (props: any) => {
  const { selectedPayment, setSelectedPayment } = props;
  return (
    <div className="flex flex-col flex-1 justify-start pt-[10px]">
      <Radio.Group
        value={selectedPayment}
        onChange={(e: any) => setSelectedPayment(e.target.value)}
      >
        {OPTIONS.map((item: any, i: any) => {
          return (
            <Radio
              className="flex flex-row-reverse pb-3 justify-start items-center"
              value={item}
              key={i}
            >
              <div className="flex flex-row w-[80vw] pb-3 text-left">
                <div className="w-[100%] flex flex-row items-center">
                  <p>{getName(item)}</p>
                  <img
                    className="ml-[10px] w-[25px]"
                    src={`/assets/${item}.png`}
                    alt={getName(item)}
                  />
                </div>
              </div>
            </Radio>
          );
        })}
      </Radio.Group>
    </div>
  );
};
