import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import {
  BottomModal,
  LoadingIcon,
  TopUpSummary,
  openWarningNotification,
} from "src/components";
import { AIRWALLET_URL } from "src/config";

export const OctopusModal = (props: any) => {
  const {
    setOpen,
    open,
    paymentDetails,
    checkPaymentOrder,
    updatePaymentOrder,
    approveOrder,
    selectedPayment,
  } = props;

  const { order_id, amount, method } = paymentDetails;
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const checkPayment = useCallback(
    async (autoFetch = false) => {
      if (loading) return;
      if (!paymentDetails["order_id"]) return;
      setLoading(true);
      const order = await checkPaymentOrder({
        orderId: paymentDetails["order_id"],
        amount: amount,
        method: method,
      });
      const status = order["status"]; //transaction
      switch (status) {
        case "order_created": //waiting for payment
          !autoFetch && openWarningNotification(t("messages.confirmPayment"));
          break;
        case "succeed": //payment success
        case "Accepted":
          await approveOrder(paymentDetails["order_id"]);
          setOpen(false);
          break;
        case "Rejected": //failed payment
          await updatePaymentOrder({
            orderId: paymentDetails["order_id"],
            status: "rejected",
          });
          openWarningNotification(t("messages.paymentExpired"));
          setOpen(false);
          break;
      }
      setLoading(false);
    },
    [
      method,
      amount,
      approveOrder,
      checkPaymentOrder,
      loading,
      paymentDetails,
      setOpen,
      setLoading,
      updatePaymentOrder,
    ]
  );

  const cancelPayment = async () => {
    await updatePaymentOrder({
      orderId: paymentDetails["order_id"],
      status: "cancelled",
    });
  };

  const openOctopusCb = useCallback(() => {
    window.open(
      `${AIRWALLET_URL}/octopus?orderId=${order_id}&amount=${amount}&method=octopus&merchant=asiatop`
    );
  }, [order_id, amount]);

  // Keep using query to check payment
  useEffect(() => {
    if (loading) return;
    const interval = setInterval(() => {
      checkPayment(true);
    }, 5000);
    return () => clearInterval(interval);
  }, [loading, checkPayment]);

  // Open AirWallet URL at init
  useEffect(() => {
    if (init) return;
    if (!order_id || !amount) return;
    openOctopusCb();
    setInit(true);
  }, [order_id, amount, init, openOctopusCb]);

  return (
    <BottomModal
      setOpen={setOpen}
      open={open}
      onCloseAction={() => cancelPayment()}
    >
      <div className="p-5 pt-3 text-center min-h-[475px] flex flex-col">
        <h5 className="text-center pb-3">{t("Octopus")}</h5>
        {paymentDetails && (
          <TopUpSummary
            selectedPayment={selectedPayment}
            topupAtpAmount={paymentDetails.amount}
            paymentOrderId={paymentDetails.order_id}
          />
        )}

        {/* Button */}
        {paymentDetails["order_id"] && (
          <div className="flex flex-col">
            {/* QR Code */}
            <div className="flex flex-col flex-1 justify-center items-center">
              {<div className="pt-[15px]" id="qr-canvas"></div>}
              {/* Do Not Refresh Message */}
              <p className="text-[#707070] pt-[20px]">{t("doNotRefresh")}</p>
            </div>

            <div className="pt-[15px]">
              <button
                className="primary full flex flex-row justify-center items-center py-3"
                onClick={() => checkPayment()}
              >
                {loading ? (
                  <LoadingIcon size={15} color="#fff" />
                ) : (
                  t("button.havePaid")
                )}
              </button>
            </div>

            <p
              className="pt-[15px] text-at-pink"
              onClick={() => openOctopusCb()}
            >
              {t("button.paidWithOctopus")}
            </p>
          </div>
        )}

        {!paymentDetails["order_id"] && (
          <div className="flex justify-center pt-[15px]">
            <LoadingIcon />
          </div>
        )}
      </div>
    </BottomModal>
  );
};
