import {
  BookOutlined,
  CrownOutlined,
  CustomerServiceOutlined,
  // FireOutlined,
  IdcardOutlined,
  LikeOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  SwapOutlined,
  TranslationOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import React from "react";

const ICON_ONE_STYLE = { fontSize: 20, color: "#ea437a" };
export const ITEMS_ONE = [
  {
    name: "coupon",
    icons: <IdcardOutlined style={ICON_ONE_STYLE} />,
    page: "/coupons-home",
  },
  {
    name: "membershipBenefit",
    icons: <CrownOutlined style={ICON_ONE_STYLE} />,
    page: "member-zone",
  },
  {
    name: "myPoints",
    icons: <MoneyCollectOutlined style={ICON_ONE_STYLE} />,
    page: "/point-exchange",
  },
  {
    name: "myCoupons",
    icons: <BookOutlined style={ICON_ONE_STYLE} />,
    page: "/my-coupons",
  },
  {
    name: "settings",
    icons: <SettingOutlined style={ICON_ONE_STYLE} />,
    page: "/edit-profile",
  },
];

const ICON_TWO_STYLE = { fontSize: 20, color: "#4269AB" };
export const ITEMS_TWO = [
  // {
  //   name: "memberEvents",
  //   icons: <FireOutlined style={ICON_TWO_STYLE} />,
  //   page: "/",
  // },
  {
    name: "referralProgram",
    icons: <LikeOutlined style={ICON_TWO_STYLE} />,
    page: "/referral",
  },
  {
    name: "exchangePoints",
    icons: <SwapOutlined style={ICON_TWO_STYLE} />,
    page: "/point-exchange",
  },
  {
    name: "cs",
    icons: <CustomerServiceOutlined style={ICON_TWO_STYLE} />,
    page: "cs",
  },
  {
    name: "languages",
    icons: <TranslationOutlined style={ICON_TWO_STYLE} />,
    page: "change-lang",
  },
  {
    name: "logout",
    icons: <UserSwitchOutlined style={ICON_TWO_STYLE} />,
    page: "/",
  },
];
