import { t } from "i18next";
import React from "react";
import { PaymentMethodOptions } from "src/components";

export const TopUpMethods = (props: any) => {
  const { selectedPayment, setSelectedPayment } = props;
  return (
    <div className="w-screen bg-white drop-shadow-md p-[20px] mt-[20px]">
      <h5>{t("topupMethod")}</h5>
      <PaymentMethodOptions
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />
    </div>
  );
};
