import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { useCouponStore } from "src/stores";
import { Coupons } from "./coupons";

export const CouponsHome = (props: any) => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { getCouponsV2 } = useCouponStore();

  useEffect(() => {
    setLoading(true);
    getCouponsV2().then((result) => {
      setLoading(false);
      if (!result) return;
      const filterResult = result.filter(
        (item: any) => parseInt(item.member_tier_required || 1) === 1
      );
      filterResult && setCoupons(filterResult);
    });
  }, [getCouponsV2, setCoupons, setLoading]);

  return (
    <Container toPage={"/"} title={t("coupon")} showExchange={true}>
      <Coupons coupons={coupons} loading={loading} />
    </Container>
  );
};
