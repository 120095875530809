import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openErrNotification, openSuccessNotification } from "src/components";
import { useReferralStore } from "../../stores";

export const ReferralForm = (props: any) => {
  const { t } = useTranslation();
  const { applyReferralCode, checkReferralStatus } = useReferralStore();
  const { user } = props;
  const inputClass =
    "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

  const [myReferralCode, setMyReferralCode] = useState("");
  const [inviterCode, setInviterCode] = useState("");
  const [isShowUseCode, setIsShowUseCode] = useState(false);

  useEffect(() => {
    if (user.referral_code) setMyReferralCode(user.referral_code);
  }, [user]);

  useEffect(() => {
    checkReferralStatus().then((result) => {
      const { appliedCode, usedReferralCode } = result;
      setInviterCode(usedReferralCode);
      setIsShowUseCode(appliedCode);
    });
  }, [setInviterCode, setIsShowUseCode, checkReferralStatus]);

  const handleChange = (e: any) => {
    e.persist();
    const { value } = e.target;
    setInviterCode(value);
  };
  const submitForm = async () => {
    if (inviterCode.length === 0) {
      openErrNotification(t("referral.form.error.empty"));
      return;
    }

    if (myReferralCode === inviterCode) {
      openErrNotification(t("referral.form.error.self"));
      return;
    }

    const result = await applyReferralCode(inviterCode);
    if (result && !result.success) {
      const { error } = result;
      switch (error) {
        case "referral_record_exist":
          openErrNotification(t("referral.errors.used"));
          return;
        default:
          openErrNotification(t("referral.errors.default"));
          return;
      }
    }
    setIsShowUseCode(true);
    return openSuccessNotification(t("referral.form.success"));
  };
  const copyToClipboard = () => {
    const element = document.createElement("textarea");
    element.value = myReferralCode;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    openSuccessNotification(t("referral.copied"));
    document.body.removeChild(element);
  };
  const AlreadyUseCode = () => {
    return (
      <div className="mx-auto w-52 h-25 mb-6">
        <div className="font-bold pb-4 tracking-widest text-[12px] text-center  text-at-pink">
          {t("referral.form.used")}
        </div>
        <div className="bg-gradient-to-r from-at-pink/50 to-at-blue/50 bg-origin-border p-4 border-4 border-dashed">
          <div className="text-center text-xl text-black font-bold">
            {inviterCode}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mx-auto w-52 h-25 mb-6">
        <div className="font-bold tracking-widest  text-[12px] pb-4 text-center text-at-pink">
          {t("myReferralCode")}
        </div>
        <div
          className="bg-gradient-to-r from-at-pink/50 to-at-blue/50 bg-origin-border p-4 border-4 border-dashed"
          onClick={() => copyToClipboard()}
        >
          <div className="text-center text-xl text-black font-bold">
            {myReferralCode}
          </div>
        </div>
      </div>
      {isShowUseCode ? (
        <AlreadyUseCode />
      ) : (
        <>
          <label className="block">
            <input
              type="text"
              name="inviterCode"
              placeholder={t("form.inviterCode")}
              className={inputClass}
              value={inviterCode}
              onChange={handleChange}
            />
          </label>
          <button
            className="full mt-4 py-3 text-xs "
            onClick={() => submitForm()}
          >
            {t("form.enter")}
          </button>
        </>
      )}
    </div>
  );
};
