import moment from "moment";

export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export const getMomentTransition = (date: string) => {
  const leng = localStorage.getItem("language");
  if (leng === "en") {
    let month = moment(date).month();
    let day = moment(date).date();
    let monthStr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthStr[month]}`;
  } else {
    let monthStr = moment(date).format("MM-DD");
    return `${monthStr}`;
  }
};

export const getWeek = (date: string) => {
  const number = moment(date).weekday();
  let weekStr = "";
  switch (number) {
    case 0:
      weekStr = "周日";
      break;
    case 1:
      weekStr = "周一";
      break;
    case 2:
      weekStr = "周二";
      break;
    case 3:
      weekStr = "周三";
      break;
    case 4:
      weekStr = "周四";
      break;
    case 5:
      weekStr = "周五";
      break;
    case 6:
      weekStr = "周六";
      break;
    default:
      weekStr = "not found";
      break;
  }
  if (moment(date).date() === moment().date()) {
    weekStr = "今天";
  }
  return `${weekStr}`;
};

export const getArrTypeToStr = (data: []) => {
  let list: any = [];
  data.forEach((v: any) => {
    list.push(...v.type);
  });
  const obj: { [key: string]: boolean } = {};
  let alistData = list
    .map((v: any) => v.name)
    .reduce((item: any, next: any) => {
      if (!obj[next]) {
        item.push(next);
        obj[next] = true;
      }
      return item;
    }, [])
    .join(",");
  return `${alistData}`;
};

export const formatDollar = (amount: any) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(amount);
};
