import React from "react";
import { useTranslation } from "react-i18next";

export const OpenGcModal = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="p-[20px] pb-[40px]">
      <h5 className="text-center">{t("services.verification")}</h5>

      <div className="pt-[20px] text-center">{t("services.verifyNote")}</div>
    </div>
  );
};
