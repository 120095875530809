import React from "react";
import { Container } from "../../components";
import { useTranslation } from "react-i18next";
import { AtByonWallet } from "./at-byon-wallet";
import { ByonBanner } from "./byon-banner";

const DISCLAIMER_STYLE = "text-slate-400 text-[11px]";

export const ServiceTopUp = (props: any) => {
  const { t } = useTranslation();

  return (
    <Container hasFooter={false} title={t("service")} toPage={"/service"}>
      {/* For BYON */}
      <ByonBanner />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {/* For 3+3 */}
        <div>
          <AtByonWallet type={"at33"} />
        </div>

        {/* For 6+6 */}
        {/* <div>
          <AtByonWallet
            setPinOpen={setPinOpen}
            setService={setService}
            type={"at66"}
          />

          <BottomModal setOpen={setPinOpen} open={pinOpen}>
            <SetPinModal service={service} setOpen={setPinOpen} />
          </BottomModal>
        </div> */}
      </div>

      {/* Disclaimer */}
      <div className="mt-5 container px-10">
        <p className={DISCLAIMER_STYLE}>BYON</p>
        <p className={DISCLAIMER_STYLE}>{t("services.disclaimer1")}</p>
        <p className={DISCLAIMER_STYLE}>{t("services.disclaimer2")}</p>
        <p className={DISCLAIMER_STYLE}>{t("services.disclaimer3")}</p>
        <p className={DISCLAIMER_STYLE}>{t("services.disclaimer4")}</p>
      </div>
    </Container>
  );
};
