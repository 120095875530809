import { t } from "i18next";
import React from "react";

export const AtBirdCollections = (props: any) => {
  const { allNftCollections, setDisplay } = props;

  return (
    <div className="container mx-auto px-5 py-5">
      <h4 className="block md:text-md font-bold text-center text-pink">
        {t(`nft.allCollections`)}
      </h4>
      <div className={`mt-5 grid grid-cols-2 gap-5`}>
        {allNftCollections.length > 0 &&
          allNftCollections.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className={`flex flex-1 justify-center duration-700 bg-white p-5 text-center rounded-[10px] drop-shadow`}
              >
                <div>
                  <h4 className="font-bold text-[12px] leading-[20px] text-pink">
                    {item.collection_name}
                  </h4>
                  <span className="block font-[600] text-[10px] text-black">
                    {`#${item.token_id}`}
                  </span>
                  {/* AT Bird Selection */}
                  <div className="w-[100%] h-[150px] mt-3">
                    <img
                      src={item.image_url || "/assets/at-ntf-bird.avif"}
                      className="w-full h-full object-cover"
                      alt="at-bird-collection"
                    />
                  </div>

                  {/* Check NFT Button */}
                  <div className="flex justify-center mt-5">
                    <button
                      className="px-2 full py-[5px] rounded-full cursor-pointer"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        setDisplay(item);
                      }}
                    >
                      <p className="rounded-full bg-pink text-white !border-none">
                        {t(`button.showAtBird`)}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
