import React from "react";
import { Footer } from "../footer";
import { Menu } from "../menu";

export const Container = (props: any) => {
  const {
    children,
    from = "",
    toPageCb = {},
    hasFooter = false,
    hasHeader = true,
    title,
    toPage = null,
    avatar = null,
    showExchange = false,
    exchangeContent = "",
  } = props;

  return (
    <div>
      {hasHeader && (
        <Menu
          from={from}
          toPageCb={toPageCb}
          avatar={avatar}
          title={title}
          toPage={toPage}
          showExchange={showExchange}
          exchangeContent={exchangeContent}
        />
      )}
      <div className="mx-auto min-h-screen w-screen pt-[120px] pb-32">
        {children}
      </div>
      {hasFooter && <Footer />}
    </div>
  );
};
