import { t } from "i18next";
import React from "react";
import { LoadingIcon } from "src/components";

const wrapStyle = "flex flex-row items-center";
const labelStyle = "w-[150px] text-[14px] text-black";
export const CardInfo = (props: any) => {
  const { loading, card, setOpen } = props;
  return (
    <div className="relative w-full bg-white flex flex-col py-[20px] px-[30px] mt-10 drop-shadow-md rounded-[24px]">
      {loading && (
        <div className="flex justify-center py-[10px]">
          <LoadingIcon />
        </div>
      )}

      {card && (
        <div className="py-[20px]">
          {/* Phone */}
          <div className={wrapStyle}>
            <p className={labelStyle}>{t("services.phoneSet")}</p>
            <img
              src={`/assets/${
                card.mob_no && card.mob_no.length > 0
                  ? "check.png"
                  : "uncheck.png"
              }`}
              alt={"check-uncheck-icon"}
            />
          </div>
          {/* Pin */}
          <div className={`${wrapStyle} mt-[10px]`}>
            <p className={labelStyle}>{t("services.pinSet")}</p>
            <img
              src={`/assets/${
                card.mod_login_passwd_status === "0"
                  ? "check.png"
                  : "uncheck.png"
              }`}
              alt={"check-uncheck-icon"}
            />
          </div>
          {/* Verification */}
          <div className={`${wrapStyle} mt-[10px]`}>
            <p className={labelStyle}>{t("services.verification")}</p>
            <img
              src={`/assets/${
                card.realname_status === "3" ? "check.png" : "uncheck.png"
              }`}
              alt={"check-uncheck-icon"}
            />
          </div>
        </div>
      )}
      {/* Verify link */}
      {card && card.realname_status !== 3 && (
        <div>
          <button
            className={`byon full !px-5 small !py-2`}
            onClick={() => setOpen(true)}
          >
            {t("services.toVerify")}
          </button>
        </div>
      )}
    </div>
  );
};
