import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
// import { Exchange } from "./exchange";
import { MyPoint } from "./my-point";
import { GetToken } from "src/utils/token";
import { history, useMyLinkStore, useMemberStore } from "src/stores";

import { EXCHANGE_LIST } from "../sample-data/exchange";

export const PointExchange = (props: any) => {
  const [myLinkPoint, setMyLinkPoint] = useState(0);
  const { t } = useTranslation();
  const { location } = props;
  const { user } = location.state || {};
  const { getMyLinkUserPoints } = useMyLinkStore();
  const { getUserMembershipInfo } = useMemberStore();
  const [currentUser, setCurrentUser] = useState(user);
  useEffect(() => {
    const initMyLinkToken = async () => {
      if (!user) {
        const memberInfo = await getUserMembershipInfo();
        setCurrentUser(memberInfo);
      }

      const token = GetToken("my-link-token");
      let myLinkPoint: any;
      if (token) {
        let result: any = await getMyLinkUserPoints(token);
        myLinkPoint = result.userPointAvailable;
        setMyLinkPoint(myLinkPoint);
      }
    };

    initMyLinkToken();
  }, [getMyLinkUserPoints, user, getUserMembershipInfo]);

  return (
    <Container toPage={"/wallet"} title={t("myPoints")}>
      {/* <Exchange /> */}
      <MyPoint list={EXCHANGE_LIST} user={{ ...currentUser, myLinkPoint }} />

      <button
        className="primary w-[90%] py-3 absolute bottom-10 mx-5"
        onClick={() => history.push("/exchange")}
      >
        {t("exchangeTitle")}
      </button>
    </Container>
  );
};
