import React from "react";
import { UserOutlined, CreditCardOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { history } from "src/stores";

const ICON_STYLE = { fontSize: 20, color: "#707070" };

export const Footer = (props: any) => {
  const isActive = (page: string) => {
    if (typeof window !== "undefined") {
      const pathname = window.location.pathname;
      return pathname === page;
    }
    return false;
  };

  return (
    <div className="h-[60px] fixed bottom-0 w-screen bg-white drop-shadow-md py-2">
      <div className="w-full h-full flex flex-row items-center self-center justify-evenly ">
        <div
          className="flex flex-col text-center pt-1 cursor-pointer"
          onClick={() => history.push("/service")}
        >
          <CreditCardOutlined
            style={{
              ...ICON_STYLE,
              ...(isActive("/service") && { color: "#ea437a" }),
            }}
          />
          <p className={`mt-1.5 ${isActive("/service") && "text-at-pink"}`}>
            {t("service")}
          </p>
        </div>

        <div
          className="flex flex-col text-center pt-1 cursor-pointer relative bottom-[20px]"
          onClick={() => history.push("/")}
        >
          <div className="h-[60px] w-[60px] rounded-full drop-shadow-md bg-white p-[12px]">
            <img
              src="/assets/AT_logo.png"
              alt="logo"
              className="h-full w-full object-fit"
            />
          </div>
          <p className={`mt-1.5 ${isActive("/") && "text-at-pink"}`}>
            {t("explore")}
          </p>
        </div>

        <div
          className="flex flex-col text-center pt-1 cursor-pointer"
          onClick={() => history.push("/wallet")}
        >
          <UserOutlined
            style={{
              ...ICON_STYLE,
              ...(isActive("/wallet") && { color: "#ea437a" }),
            }}
          />
          <p className={`mt-1.5 ${isActive("/wallet") && "text-at-pink"}`}>
            {t("profile")}
          </p>
        </div>
      </div>
    </div>
  );
};
