export const AIR_WALLEX_OPTIONS = [
  "fps",
  // "credit_card",
  "wechat_pay",
  "alipay",
];

export const OPTIONS = [...AIR_WALLEX_OPTIONS, "octopus", "payme"];

export const getName = (item: string) => {
  switch (item) {
    case "fps":
      return "FPS";
    case "credit_card":
      return "Visa / Master / Union / AE";
    case "wechat_pay":
      return "Wechat Pay";
    case "alipay":
      return "Alipay";
    case "atome":
      return "Atome";
    case "octopus":
      return "Octopus";
    case "payme":
      return "Payme";
    default:
      return item;
  }
};
