export const services_at33 = [
  {
    name: "MallQS",
    merchant: "MallQS",
    img: "/assets/mallqs.png",
    desc: `MALLQS.COM代表現代奢華購物, 滿足美容和健康客戶最完滿 指標聖地。
萬龍魔方由萬龍國際集團控股有限公司所有，憑藉其創新, 專業知識和企業家精神贏得了
美容奢華購物先鋒的美譽。
萬龍 魔方於2018年在香港成立，美容和健康是我們的本命(DNA) 。
萬龍 魔方蒐集超過300+ 獨特的不同成熟和新興產品系列提供簡單而 幸福的購物體驗。`,
    tnc: `MALLQS.COM代表現代奢華購物, 滿足美容和健康客戶最完滿 指標聖地。
萬龍魔方由萬龍國際集團控股有限公司所有，憑藉其創新, 專業知識和企業家精神贏得了
美容奢華購物先鋒的美譽。
萬龍 魔方於2018年在香港成立，美容和健康是我們的本命(DNA) 。
萬龍 魔方蒐集超過300+ 獨特的不同成熟和新興產品系列提供簡單而 幸福的購物體驗。`,
    service: "at",
    url: null,
  },
  {
    name: "Re:Verse",
    merchant: "reverse",
    img: "/assets/reverse_logo.jpeg",
    desc: `Re:verse is a truly open decentralised Asset Management platform for customers to store and swap all kinds of digital assets in One App with One password! Our vision is to disrupt the loyalty market with innovative business models and technology.`,
    tnc: `Re:verse is a truly open decentralised Asset Management platform for customers to store and swap all kinds of digital assets in One App with One password! Our vision is to disrupt the loyalty market with innovative business models and technology.`,
    service: "at",
    url: "https://marketplace.reversedao.io",
  },
];

export const services_at66 = [];
