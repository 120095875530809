import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import {
  BottomModal,
  LoadingIcon,
  TopUpSummary,
  openNotificationWithIcon,
} from "src/components";
import QRCodeStyling from "qr-code-styling";

export const PaymeModal = (props: any) => {
  const {
    setOpen,
    open,
    paymentDetails,
    checkPaymentOrder,
    updatePaymentOrder,
    approveOrder,
    selectedPayment,
  } = props;
  const { webLink = "" } = paymentDetails;
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const qrCode: any = new QRCodeStyling({
    width: 148,
    height: 148,
    type: "svg",
    data: webLink,
    image: "/assets/payme-logo-P-red-circle.png",
    dotsOptions: {
      color: "#dc0010",
      type: "dots",
    },
    backgroundOptions: {
      color: "#fff",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0,
      imageSize: 0.5,
      hideBackgroundDots: false,
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  const checkPayment = useCallback(
    async (autoFetch = false) => {
      if (loading) return;
      if (!paymentDetails["order_id"]) return;
      setLoading(true);
      const order = await checkPaymentOrder({
        orderId: paymentDetails["order_id"],
      });
      const status = order["statusCode"];
      switch (status) {
        case "PR001": // waiting for payment
          !autoFetch &&
            openNotificationWithIcon(
              "warning",
              t("messages.warning"),
              t("messages.confirmPayment")
            );
          break;
        case "PR005": // success
          await approveOrder(paymentDetails["order_id"]);
          setOpen(false);
          break;
        case "PR007": // expired
          await updatePaymentOrder({
            orderId: paymentDetails["order_id"],
            status: "expired",
          });
          openNotificationWithIcon(
            "warning",
            t("messages.warning"),
            t("messages.paymentExpired")
          );
          setOpen(false);
          break;
      }
      setLoading(false);
    },
    [
      approveOrder,
      checkPaymentOrder,
      loading,
      setLoading,
      paymentDetails,
      setOpen,
      updatePaymentOrder,
    ]
  );

  const cancelPayment = async () => {
    await updatePaymentOrder({
      orderId: paymentDetails["order_id"],
      status: "cancelled",
    });
  };

  const openPayme = () => {
    window.open(webLink);
  };

  // Keep using query to check payment
  useEffect(() => {
    if (loading) return;
    const interval = setInterval(() => {
      checkPayment(true);
    }, 5000);
    return () => clearInterval(interval);
  }, [loading, checkPayment]);

  // Assign QR Code
  useEffect(() => {
    const qr = document.getElementById("qr-canvas");
    if (!qr) setInit(false);
    if (qr) {
      !init && qrCode.append(qr);
      setInit(true);
    }
  }, [qrCode, init, setInit]);

  if (!qrCode) return null;

  return (
    <BottomModal
      setOpen={setOpen}
      open={open}
      onCloseAction={() => cancelPayment()}
    >
      <div className="p-5 pt-3 text-center min-h-[475px] flex flex-col">
        <h5 className="text-center pb-3">{t("payme")}</h5>
        {paymentDetails && (
          <TopUpSummary
            selectedPayment={selectedPayment}
            topupAtpAmount={paymentDetails.amount}
            paymentOrderId={paymentDetails.order_id}
          />
        )}

        {/* Button */}
        {paymentDetails["order_id"] && (
          <div className="flex flex-col">
            {/* QR Code */}
            <div className="flex flex-col flex-1 justify-center items-center">
              {<div className="pt-[15px]" id="qr-canvas"></div>}
              {/* Do Not Refresh Message */}
              <p className="text-[#707070] pt-[20px]">{t("doNotRefresh")}</p>
            </div>

            <div className="pt-[15px]">
              <button
                className="primary full flex flex-row justify-center items-center py-3"
                onClick={() => checkPayment()}
              >
                {loading ? (
                  <LoadingIcon size={15} color="#fff" />
                ) : (
                  t("button.havePaid")
                )}
              </button>
            </div>

            <p className="pt-[15px] text-at-pink" onClick={() => openPayme()}>
              {t("button.paidWithPayme")}
            </p>
          </div>
        )}

        {!paymentDetails["order_id"] && (
          <div className="flex justify-center pt-[15px]">
            <LoadingIcon />
          </div>
        )}
      </div>
    </BottomModal>
  );
};
