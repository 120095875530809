import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  LoadingIcon,
  openNotificationWithIcon,
  BottomModal,
} from "src/components";
import { history, useMyLinkStore, useMemberStore } from "src/stores";
import { GetToken } from "src/utils/token";

export const Exchange = (props: any) => {
  const { getMyLinkUserPoints, getPointLevel, exchange } = useMyLinkStore();
  const { getUserMembershipInfo } = useMemberStore();
  const [atPoint, setAtPoint] = useState(0);
  const [myLinkPoint, setMyLinkPoint] = useState(0);
  const [currentPointLevels, setCurrentPointLevels] = useState([]);
  const [currentPointLevel, setCurrentPointLevel] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // get the points level
  const initMyLinkToken = useCallback(async () => {
    const token = GetToken("my-link-token");
    let myLinkPoint: any;
    if (token) {
      let result: any = await getMyLinkUserPoints(token);
      myLinkPoint = result.userPointAvailable;
      setMyLinkPoint(myLinkPoint);
    }

    const pointLevel = await getPointLevel();
    setCurrentPointLevels(pointLevel);
    console.log("poimtLevel", pointLevel);
  }, [getMyLinkUserPoints, getPointLevel]);

  useEffect(() => {
    initMyLinkToken();

    getUserMembershipInfo().then((member) => {
      const { at_points } = member;

      if (at_points) setAtPoint(at_points);
    });
  }, [initMyLinkToken, getUserMembershipInfo]);

  const handleExchange = async () => {
    try {
      setLoading(true);
      await exchange(currentPointLevel.pointLevelCode);
      setLoading(false);
      initMyLinkToken();
      openNotificationWithIcon(
        "info",
        t("messages.congraz"),
        t("exchangeSuccess")
      );
      setOpen(false);
      history.push("point-exchange");
    } catch (e) {
      openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.exchangedFailed")
      );
    }
  };

  return (
    <Container title={`${t("exchangeTitle")}`} toPage="/">
      <div className="flex flex-col">
        <div className="bg-white  border-[1px] w-full px-8 py-5">
          <div>
            <h5 className="text-base font-semibold tracking-wider text-[#4269AB]">
              {t("from")} MyLink {t("points")}
            </h5>
            <div className="flex-row items-center mt-[10px]">
              <img
                className="w-[29px] mr-[15px]"
                src="/assets/mylink.webp"
                alt="mylink-logo"
              />
              <p className="font-[600] text-[14px]">{myLinkPoint}</p>
              <p className="font-normal text-[12px] mt-1 ml-2">MYLINK</p>
            </div>
          </div>

          <div className="mt-[15px]">
            <h5 className="text-base font-semibold">
              {t("to")} AT {t("points")}
            </h5>
            <div className="flex-row items-center mt-[10px]">
              <img
                className="w-[29px] h-auto mr-[15px]"
                src="/assets/AT_logo.jpg"
                alt="at-logo"
              />
              <p className="font-[600] text-[14px]">{atPoint}</p>
              <p className="font-normal text-[12px] mt-1 ml-2">AT</p>
            </div>
          </div>
        </div>

        <div className="grid row-auto	grid-cols-2	 md:grid-cols-3 gap-2 p-2">
          {currentPointLevels &&
            currentPointLevels.length > 0 &&
            currentPointLevels.map((pointLevel, index) => {
              const { pointLevelCode, pointLevelValue } = pointLevel;

              const active =
                currentPointLevel.pointLevelCode === pointLevelCode
                  ? "primary"
                  : "";

              return (
                <div
                  key={index}
                  className={`bg-white  rounded-2xl border-[1px] w-[179px] ${active} p-[2px]`}
                  // onClick={() => handleExchange(pointLevelCode)}
                  onClick={() => setCurrentPointLevel(pointLevel)}
                >
                  <div className="flex flex-row items-center h-full w-full bg-white rounded-xl p-2">
                    <div>
                      <img
                        className="w-[40px] mr-2 rounded-2xl"
                        src="/assets/mylink.webp"
                        alt="mylink-logo"
                      />
                    </div>
                    <div className="flex flex-col flex-1 mt-2">
                      <h5 className="font-semibold text-[20px] text-black tracking-wider leading-3">
                        {pointLevelValue}
                      </h5>
                      <div className="flex flex-row">
                        <h5 className="font-normal text-[10px]	text-black flex-1 tracking-wider">
                          {pointLevelValue / 100} AT
                        </h5>
                        <h5 className="font-normal text-[12px]	text-black flex-1">
                          MYLINK
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <p className="self-center absolute bottom-24 font-bold">
          {t("exchangeRateTxt")} 1 AT : 100 MYLINK
        </p>

        <button
          className="primary absolute bottom-10 h-10 w-[90%] left-5 right-5"
          onClick={() => {
            if (!currentPointLevel.pointLevelCode) {
              openNotificationWithIcon(
                "warning",
                t("messages.warning"),
                t("messages.mylinkPointInsufficient")
              );
              return;
            }
            setOpen(true);
          }}
        >
          {t("exchangeNow")}
        </button>
      </div>

      <BottomModal setOpen={setOpen} open={open}>
        <div className="p-5 flex flex-col items-center">
          <h5 className="mb-5"> {t("confirmExchange")}</h5>

          <div className="flex-row w-[90%]">
            <p className="flex-1">AT {t("points")}</p>
            <p className="text-[#60BE38]">
              +{currentPointLevel.pointLevelValue / 100}
            </p>
          </div>

          <div className="flex-row w-[90%] mt-5">
            <p className="flex-1">MyLink {t("points")}</p>
            <p className="primary ">-{currentPointLevel.pointLevelValue}</p>
          </div>

          <div className="flex-col justify-center w-full mt-40 items-center">
            {loading ? (
              <div>
                <LoadingIcon />
              </div>
            ) : (
              <div className="w-full">
                <button
                  className="primary w-full py-3"
                  onClick={() => handleExchange()}
                >
                  {t("confirmExchange")}
                </button>

                <button
                  className="primary w-full mt-5 p-[2px]"
                  onClick={() => {
                    setCurrentPointLevel({});
                    setOpen(false);
                  }}
                >
                  <div className="flex flex-row items-center h-full w-full bg-white rounded-3xl px-[20px] py-3 justify-center">
                    <p className="primary">{t("backUp")}</p>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </BottomModal>
    </Container>
  );
};
