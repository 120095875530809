import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class CardStore {
  @action
  async getCardInfo(args: any) {
    const url = `${Configs.apiUrl}/card/get-card${args ? args : ""}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async getGcCardOtp(args: any) {
    const url = `${Configs.apiUrl}/card/get-gc-otp${args ? args : ""}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async setGcPin(data: any) {
    const url = `${Configs.apiUrl}/card/reset-gc-pin`;
    try {
      const result: any = await axios.put(url, data, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_CARD = "cardStore";
