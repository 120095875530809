import { t } from "i18next";
import React from "react";
import i18n from "src/i18n";

export const DisplayNft = (props: any) => {
  const { displayAtBird = {}, onClickHandler, hasNft = false } = props;

  return (
    <div className="block container mx-auto px-10 pt-5">
      {/* AT Bird Collection */}
      <div className="flex flex-col justify-center items-center">
        <h4 className="font-bold text-pink">{displayAtBird.collection_name}</h4>
        {displayAtBird.token_id && (
          <span className="block font-[600] text-xs text-black">
            #{displayAtBird.token_id}
          </span>
        )}

        {!displayAtBird.token_id && <h4> {t(`nft.displayDummyNftHeader`)}</h4>}
        <div className="mt-5 w-[100%] min-h-[300px] overflow-hidden">
          <img
            src={
              displayAtBird.image_url ||
              "https://i.seadn.io/gcs/files/726395958f18d3f6385f75b0fed57e7e.png?w=500&auto=format"
            } //Dummy
            alt="at-nft-bird"
            className="min-h-[inherit] h-full w-full object-cover object-center"
          />
        </div>
      </div>

      <div className="mt-5 mb-4">
        <div className="flex justify-between items-center">
          <div className="flex flex-row justify-center w-[100%]">
            <h4 className="text-pink flex flex-1">
              {t(`nft.outstandingDiscount`)}
            </h4>
            {/* Show At Bird QR - Hide for now*/}
            {/* <div>
              <button
                onClick={() => onQrClick()}
                className="md:px-12 py-1 rounded-full"
              >
                <p className="rounded-full bg-pink text-white !border-none">
                  {t(`button.showQrCode`)}
                </p>
              </button>

              {openModal && displayAtBird && (
                <ShowAtBirdQrModal
                  displayAtBird={displayAtBird}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              )}
            </div> */}
          </div>
        </div>

        <div className="flex flex-col pl-4 pt-3">
          <ul>
            {displayAtBird.utilities &&
              displayAtBird.utilities.map((item: any, i: any) => {
                return (
                  <li
                    key={i}
                    className="md:text-base text-[12px] rounded-full text-gray list-disc"
                  >
                    {item[i18n.language]}
                  </li>
                );
              })}
            {!displayAtBird.utilities && (
              <>
                <li className="md:text-base text-[12px] rounded-full text-gray list-disc">
                  {t(`nft.${displayAtBird.type || "AT Birds"}.desc1`)}
                </li>
                <li className="md:text-base text-[12px] rounded-full text-gray list-disc">
                  {t(`nft.${displayAtBird.type || "AT Birds"}.desc2`)}
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {!hasNft && (
        <div className="w-full">
          <button
            onClick={onClickHandler}
            className="w-full md:px-12 py-3 rounded-full"
          >
            <p className="rounded-full bg-pink text-white !border-none">
              {t(`button.goToMintingPage`)}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};
