import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { history } from "src/stores";

export const TopUpDone = (props: any) => {
  const { t } = useTranslation();

  return (
    <Container title={`${t("topUpDone")}`}>
      <div className="flex flex-col flex-1 h-full justify-center items-center">
        <div className="w-32 h-32 overflow-hidden mt-[50px] ml-4">
          <img
            src={"/assets/location.png"}
            alt={"coffee-icon"}
            className=" w-full h-full object-cover"
          />
        </div>

        <h5 className="text-[12px] text-[#707070] mt-5">{t("topUpSuccess")}</h5>
        <button
          className="mt-5 py-2 mb-2 w-[150px]"
          onClick={() => history.push("/coupons-home")}
        >
          {t("explore")}
          {t("coupon")}
        </button>
        <button
          className="mt-1 py-2 mb-2 w-[150px] secondary"
          onClick={() => history.push("/wallet")}
        >
          {t("button.returnToWallet")}
        </button>
      </div>
    </Container>
  );
};
