import React from "react";
import { useTranslation } from "react-i18next";

export const CS = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="p-5 pb-10 flex flex-col text-center max-w-[300px] mx-auto">
      <h5 className="text-center">{t("cs")}</h5>
      {/* Email */}
      <div className="flex flex-row mt-5">
        <p className="flex flex-1">{t("email")}</p>
        <p className="text-black font-bold">enquiry@aisatop.asia</p>
      </div>
      {/* Phone */}
      <div className="flex flex-row mt-2">
        <p className="flex flex-1">{t("phone")}</p>
        <a
          className="text-at-blue font-bold no-underline"
          href="tel:+85223228350"
        >
          +852 2322 8350
        </a>
      </div>
      {/* Whatsapp */}
      <div className="flex flex-row mt-2">
        <p className="flex flex-1">Whatspp</p>
        <a
          className="text-at-blue font-bold no-underline"
          href="https://wa.me/85293116081"
          target="_blank"
          rel="noopener noreferrer"
        >
          +852 9311 6081
        </a>
      </div>
      {/* Working Time */}
      <div className="flex flex-col mt-2 text-left">
        <p className="flex flex-1">{t("workingTimes")}</p>
        <p className="text-black font-bold mt-2">{t("wokringTimeDetailOne")}</p>
        <p className="text-black font-bold">{t("wokringTimeDetailTwo")}</p>
      </div>
    </div>
  );
};
