import { t } from "i18next";
import React, { useState } from "react";
import {
  openNotificationWithIcon,
  openWarningNotification,
} from "src/components";
import { useAuthStore } from "src/stores";
import { history } from "src/stores";
const labelClass = " block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

const Form = () => {
  const [countryCode, setCountryCode] = useState("852");
  const [phoneNum, setPhoneNum] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { getOtpCode, access } = useAuthStore();

  const handleInput = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "country") return setCountryCode(value);
    if (type === "phoneNum") return setPhoneNum(value);
    if (type === "otp") return setOtp(value);
  };

  const getCode = async () => {
    if (loading) return;
    setLoading(true);
    if (countryCode === "" || phoneNum === "") {
      setLoading(false);
      return openNotificationWithIcon(
        "warning",
        t("messages.pleaseWait"),
        t("messages.getCodeNoFieldErr")
      );
    }

    const isOtpCodeSent = await getOtpCode(phoneNum, countryCode);
    setLoading(false);
    if (!isOtpCodeSent.success) {
      return openNotificationWithIcon(
        "warning",
        t("messages.pleaseWait"),
        t("messages.sendOtpFail")
      );
    }

    return openNotificationWithIcon(
      "success",
      t("messages.congraz"),
      t("messages.sendOtpSuccess")
    );
  };

  const verifyCode = async () => {
    if (loading) return;
    setLoading(true);
    if (countryCode === "" || phoneNum === "" || otp === "") {
      setLoading(false);
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.verifyOtpFail")
      );
    }

    const isLoggedIn = await access({ phoneNum, countryCode, otp });
    setLoading(false);
    if (!isLoggedIn.success)
      return openWarningNotification(t(`messages.${isLoggedIn.error}`));

    history.push("/");
    return openNotificationWithIcon(
      "success",
      t("messages.congraz"),
      t("messages.loginSuccess")
    );
  };

  return (
    <div>
      <div className="block">
        <p className={labelClass}>{t("form.phone")}</p>
        <div className="flex flex-row relative">
          <input
            type="text"
            name="country"
            className={`${inputClass} w-20 mr-2 pl-6`}
            onChange={(e) => handleInput(e, "country")}
            value={countryCode}
          />
          <p className="text-slate-500 absolute bottom-[10px] left-[10px]">+</p>
          <input
            type="text"
            name="number"
            className={inputClass}
            onChange={(e) => handleInput(e, "phoneNum")}
            value={phoneNum}
          />
        </div>
      </div>

      <div className="pt-5 relative">
        <p className={labelClass}>{t("form.code")}</p>
        <input
          type="text"
          name="code"
          className={`${inputClass} pr-20`}
          onChange={(e) => handleInput(e, "otp")}
          value={otp}
        />
        <p
          className="text-at-pink absolute bottom-[9px] right-[10px] cursor-pointer"
          onClick={() => getCode()}
        >
          {t("form.getCode")}
        </p>
      </div>

      <button className="full mt-10 py-3 text-xs" onClick={() => verifyCode()}>
        {t("form.register")}
      </button>
    </div>
  );
};

export default Form;
