import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { useCinemaStore } from "src/stores";
import { LoadingIcon } from "src/components";
import { history } from "src/stores";
import moment from "moment";

export const MovieDetail = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [cinemaMovieDetail, setcinemaMovieDetail] = useState<any>({});
  const { location } = props;
  const { param, prevPath } = location.state;
  const { t } = useTranslation();
  const { getCinemaMovieDetail } = useCinemaStore();

  useEffect(() => {
    getCinemaMovieDetail(param).then((result) => {
      setLoading(false);
      if (result) return setcinemaMovieDetail(result);
    });
  }, [param, getCinemaMovieDetail, setcinemaMovieDetail]);

  const hasCinemaMovieDetail = useMemo(() => {
    return cinemaMovieDetail && Object.keys(cinemaMovieDetail).length > 0;
  }, [cinemaMovieDetail]);

  const hasFileType = useMemo(() => {
    return (
      cinemaMovieDetail.film_type &&
      Object.keys(cinemaMovieDetail.film_type).length > 0
    );
  }, [cinemaMovieDetail]);

  return (
    <Container
      toPage={prevPath}
      title={hasCinemaMovieDetail ? cinemaMovieDetail.title : t("backUp")}
    >
      {loading && (
        <div className="flex justify-center flex-1 py-5">
          <LoadingIcon />
        </div>
      )}
      {hasCinemaMovieDetail && (
        <>
          <div className="bg-white drop-shadow-md p-5 overflow-hidden overflow-x-scroll scroll-px-5 snap-x ">
            <div className="grid grid-cols-2 gap-2">
              <div className="row-span-6">
                <img
                  src={cinemaMovieDetail.image}
                  className="w-full object-cover"
                  alt=""
                />
              </div>
              <div className="row-span-5 ml-3 leading-4">
                {hasFileType &&
                  cinemaMovieDetail.film_type.map((item: any, i: number) => {
                    return (
                      <button key={i} className="p-px rounded-full">
                        <span className="block text-xs px-1  rounded-full bg-white text-pink">
                          {item.name}
                        </span>
                      </button>
                    );
                  })}
                <button className="p-px rounded-full">
                  <p className="block text-xs px-1  rounded-full bg-white text-pink">
                    {cinemaMovieDetail.language}
                  </p>
                </button>
                <div className="text-slate-400 text-xs mt-2">
                  {cinemaMovieDetail.level},{cinemaMovieDetail.duration}
                </div>
              </div>
              <div className="flex flex-col-reverse">
                <p className="col-span-1 ml-3 font-medium text-slate-400">
                  《
                  {moment(cinemaMovieDetail.start_date).format(
                    "yyyy年MM月DD日"
                  )}
                  》
                </p>
              </div>
            </div>
            <div className="mt-3 indent-8">
              {cinemaMovieDetail.introduction}
            </div>
          </div>
          <div className="px-32 mt-20">
            <button
              className="full py-3 text-xs"
              onClick={() =>
                history.push("/movie-showing-time", {
                  filmId: cinemaMovieDetail.id,
                })
              }
            >
              {t("movieShowTimes")}
            </button>
          </div>
        </>
      )}
    </Container>
  );
};
