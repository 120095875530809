import { t } from "i18next";
import React from "react";
import { LoadingIcon } from "src/components";

export const Card = (props: any) => {
  const { card, loading, balance } = props;

  const getMerchantName = () => {
    if (!card) return "";
    switch (card.merchant_no) {
      case "000000000004":
      case "826753622651":
        return `QonBay`;
      case "026367578515":
        return "Re:Verse";
      default:
        return "";
    }
  };

  const getMerchantBg = () => {
    if (!card || !card.merchant_no) return "url('/assets/card.png')";
    return `url('/assets/${card.merchant_no}.png')`;
  };

  return (
    <div
      className="p-[30px] min-h-[180px] w-[100%] rounded-[20px] bg-cover relative flex flex-col drop-shadow-md"
      style={{ backgroundImage: getMerchantBg() }}
    >
      {/* Backdrop */}
      {card && (
        <div className="backdrop-blur-sm bg-black/50 w-full h-full absolute top-0 left-0 rounded-[20px]" />
      )}

      {/* Logo */}
      <img
        src="/assets/AT_logo_white.png"
        alt="logo-white"
        className="absolute top-[30px] right-[30px] w-[30px] object-fit"
      />

      {!loading && (
        <div className="z-10 relative">
          <p className="text-white text-[14px]">{getMerchantName()}</p>
          <p className="text-white text-[14px] mt-[20px]">
            {t("services.available")}
          </p>

          <h2 className="text-white font-[600] text-[24px] mt-[5px] flex-row flex-1">
            HKD {balance}
          </h2>

          <p className="text-white text-[14px] font-[600] text-[12px] mt-[20px] tracking-[2px]">
            {card ? card.card_number : ""}
          </p>

          <div className="flex flex-row mt-[10px] justify-between">
            <p className={$labelText} style={{ fontSize: 8 }}>
              {card
                ? `${card.expire_date.substring(
                    4,
                    6
                  )}/${card.expire_date.substring(0, 4)}`
                : ""}
            </p>
            <p className={$labelText} style={{ fontSize: 8 }}>
              {card ? `${card.card_verify_code}` : ""}
            </p>
          </div>
        </div>
      )}

      {loading && (
        <div className="flex h-full justify-center items-center absolute top-0 w-full left-0">
          <LoadingIcon size={20} color={"white"} />
        </div>
      )}
    </div>
  );
};

const $labelText = `text-white font-[600] tracking-[2px]`;
