export const tcTranslation = {
  changeLang: "Change Language",
  explore: "探索",
  profile: "會員專區",
  debitCard: "金融卡",
  wallet: "錢包",
  service: "服務",
  exclusiveEvents: "獨家活動",
  trendingCoupons: "熱門優惠券",
  newMovies: "最新電影",
  all: "所有",
  coupon: "優惠券",
  detail: "詳情",
  purchased: "已購買",
  expiration: "到期日",
  membershipBenefit: "黑曜專區",
  myPoints: "我的積分",
  myCoupons: "我的優惠券",
  myReferralCode: "我的推薦碼",
  settings: "編輯個人",
  referralProgram: "推薦計劃",
  newMemberEvents: "新人活動",
  memberEvents: "會員活動",
  exchangePoints: "積分換兌",
  exchangeRate: "最新兑换率 1 HKD : 1 AT",
  languages: "語言",
  logout: "登出",
  cs: "官方客服",
  email: "電郵地址",
  phone: "電話號碼",
  workingTimes: "工作時間",
  wokringTimeDetailOne: "星期一至週五（9：30-17：00）。",
  wokringTimeDetailTwo: "在星期六，週日和公共假期關閉。",
  backUp: "返回",
  from: "從",
  to: "到",
  or: "或",
  use: "使用",
  paymentOptions: "付款選項",
  pay: "付款",
  payme: "Payme",
  topup: "購買",
  confirmTopup: "確認充值",
  topUpDone: "充值成功",
  topUpSuccess: "已成功充值！",
  topupNow: "立即充值",
  topupAtpoints: "充值 AT",
  topupAmount: "充值金額 (HKD)",
  topupMethod: "充值方式",
  orderNumber: "訂單號碼",
  doNotRefresh: "付款後請勿刷新頁面...",
  atPoints: "AT 積分",
  points: "積分",
  requiredTopUpPoints: "需要 AT 積分",
  setPin: "設置您的 6 位付款密碼",
  otp: "請輸入驗證碼",
  pin: "請輸入您想設置的付款密碼",
  noCouponFound: "暫時查無優惠券。",
  noMovieFound: "暫時查無電影。",
  checkOut: "查看",
  movieShowTimes: "查看電影時間",
  buyTicket: "前往購票",
  moviePrice: "票價",
  allVersions: "所有版本",
  noMovieShowingTime: "暫無電影場次",
  minute: "分鐘",
  clickToCopy: "點擊以複製兌換碼",
  confirmIsUsed: "確認此兌換券已經換兌嗎？",
  used: "已使用",
  exchangeNow: "立即轉換",
  exchangeRateTxt: "兌換率",
  confirmExchange: "確認轉換",
  notSelectExchangeItems: "請先選擇轉換項目",
  exchangeTitle: "積分轉換",
  exchangeSuccess: "兌換成功",
  exchangeFailed: "兌換失敗，請稍後重試！",
  referral: {
    title: "我的推薦碼",
    form: {
      referralCode: "邀請人推薦碼",
      codeConfirm: "確定",
      success: "邀請碼使用成功",
      error: {
        empty: "邀請碼不可空",
        self: "不可使用自己的邀請碼",
      },
      used: "你已使用此邀請碼",
    },
    errors: {
      default: "該邀請碼無效！",
      used: "你已使用邀請碼！",
    },
    copied: "成功複制",
    subtitle: "推薦福利",
    promo1: "關注我們以獲得最新優惠",
    promo2: "邀請朋友購買優惠券，即可獲得 AT Rewards 積分",
  },
  services: {
    available: "餘額",
    toByonOpen: "前往 BYON 開通",
    byonChecking: "BYON 審批中",
    byonApproved: "更多 BYON 金額",
    availableMerchants: "可用商戶",
    openService: "申請 byonPAYLATER {{type}} 服務",
    paymentDesc: "還款假期後需每月還款",
    paymentNote33:
      "三個月還款假期： 享有首三個月免息還款假期，之後分三期 (3+3) 還款",
    paymentNote66:
      "六個月還款假期： 享有首六個月免息還款假期，之後分六期 (6+6) 還款",
    extraNote:
      "*以上還款金額以每月平息 0.39% 計算，表內所顯示的還款金額以及期限僅供參考。",
    serviceTitle: "「{{type}}」還款假期",
    serviceDesc: {
      at33: "指定商戶可享有首三個月免息免供還款假期，後三個月以低至每月平息0.15%還款，消費更輕鬆！",
      at66: "指定商戶可享有首六個月免息免供還款假期，後六個月以低至每月平息0.15%還款，輕鬆還款！",
    },
    disclaimer1: "歐力士財務服務香港有限公司旗下品牌",
    disclaimer2: "放債人牌照號碼: 1813/2022",
    disclaimer3: "忠告：借錢梗要還， 咪俾錢中介",
    disclaimer4: "客戶服務／投訴熱線： (852) 2862 9119",
    topup: "充值",
    activate: "獲取專款專用卡",
    cardInfo: "卡號詳情",
    phoneSet: "電話號碼設置",
    pinSet: "六位 PIN 設置",
    verification: "身份認證",
    toVerify: "前往身份認證",
    scanToPay: "掃碼付款",
    verifyNote:
      "請以電腦瀏覽 https://www.globalcash.cn/v4/activate/index 以登入並進行身份認證。",
  },
  ticket: {
    place: "適用地點",
    hk: "香港",
    howToUse: "如何使用",
    tnc: "條款與細則",
    get: "立即獲取",
    use: "立即使用",
  },
  button: {
    returnToWallet: "返回錢包",
    returnToMyPoints: "返回我的積分",
    continueExploring: "繼續瀏覽",
    editProfile: "更新資料",
    havePaid: "我已付款",
    paidWithPayme: "開啟 Payme 付款",
    paidWithOctopus: "前往 Octopus 付款",
    soldOut: "已售罄",
    loading: "讀取資料中⋯",
    setPin: "設定付款碼",
    getOtp: "獲取驗證碼",
    nftCollection: "我的 NFT 收藏品",
    showQrCode: "展示二維碼",
    goToMintingPage: "探索 AT Birds 並成為黑曜石會員",
    showAtBird: "查看",
    copy: "",
    close: "關閉",
    payNow: "前往付款",
    goToTopup: "去充值",
    confirm: "確認",
    cancel: "取消",
    octopus: "八達通付款",
    back: "返回",
  },
  myNftCollection: "所有 NFT 收藏品",
  nft: {
    noWalletAddress: "找不到您的錢包地址",
    displayDummyNftHeader: "AsiaTop NFT 收藏品",
    myCollectionHeader: "AT BIRD 收藏品",
    allCollections: "所有 NFT 收藏品",
    outstandingDiscount: "持有優惠",
    copied: "",
    confirm: "確定",
    cancel: "取消",
    "AT Birds": {
      desc1: "持有 AT Bird 升級為黑曜石會員",
      desc2: "更多優惠即將公佈",
    },
  },
  card: {
    tier: "會員階級",
    graz: "歡迎！",
    jade: "Jade",
    GOLD: "Gold",
    DIAMOND: "Diamond",
    OBSIDIAN: "OBSIDIAN",
    stat: "讓我們享受這些精彩優惠吧！",
  },
  messages: {
    pleaseWait: "敬請期待",
    comingSoon: "快將推出。",
    congraz: "恭喜",
    warning: "稍等一下",
    error: "抱歉",
    getCodeNoFieldErr: "請輸入您的區號及電話號碼。",
    sendOtpFail: "暫時無法發出認證碼，請確認您的區號及電話號碼。",
    sendOtpSuccess: "我們已把認證碼發送到您的號碼，此認證碼可維持五分鐘。",
    verifyOtpFail: "暫時無法認證認證碼，請確認您的認證碼、區號及電話號碼。",
    loginSuccess: "已成功登入。",
    pleaseEnterTopupAmount: "請輸入充值金額",
    createPaymentFailed: "建立付款失敗",
    updateUserFail: "暫時未能更新您的個人資料。",
    updateUserSuccess: "您的個人資料已經更新。",
    noCoupon: "暫時沒有可用的優惠券。",
    approveOrderErr: "暫時無法處理您的充值，請聯絡我們客服。",
    mylinkPointInsufficient: "請先換取 AT Points",
    confirmPayment: "請確認您已付款。",
    paymentExpired: "付款碼已過期，請重新嘗試。",
    couponSoldout: "優惠券已售罄。",
    formNotReady: "請先提交 BYON 表格。",
    cardNotReady: "正在準備您的錢包。",
    tryAgain: "請重新嘗試。",
    requireOtp: "請輸入六位驗證碼。",
    requirePwd: "請輸入六位支付密碼。",
    pinSet: "已設置付款密碼，請選擇服務。",
    formNotApproved: "BYON 表格正在審批中。",
    merchantNotSame: "此二維碼屬於別的商戶。",
    cannotPay: "暫時無法付款，請重新嘗試。",
    obsidianLevelRequired: "您的會員等級不足，請考慮升級至黑躍石會員",
    failedToUserInactive: "你的帳號已被封鎖，請聯絡客服解封",
    failedToVerifyUser: "無法驗證用户",
    logout: "已成功登出。",
    otpIncorrect: "OTP不正確，請重新輸入。",
    failedToUpdatePaymentOrder: "更新訂單失敗",
    nft_or_tier_required: "會員等級不足或未持有特定 NFT",
    disclaimer: "請先同意免責聲明才能成為 AT Rewards 會員",
    successfullyPurchasedCoupon: "成功購買優惠券。",
    failedToPurchaseCoupon: "暫時無法購買優惠券。",
    redeemedCouponSuccess: "成功兌換優惠券。",
    failedToRedeemCoupon: "暫時無法兌換優惠券。",
  },
  disclaimer: {
    myLink:
      "點擊“我同意”即表示您同意我們的服務條款並同意成為 AT Rewards 會員。",
    agree: "我同意",
    discard: "不同意",
  },
  form: {
    name: "暱稱",
    email: "電郵地址",
    dob: "出生日期",
    submit: "提交",
    save: "保存資料",
    countryName: "區號",
    phone: "電話號碼",
    code: "驗證碼",
    register: "登錄",
    getCode: "獲取認證碼",
    enter: "確認",
    inviterCode: "邀請人推薦碼",
  },
  point: {
    pointNumber: "積分數量",
    enter: "確認",
  },
  screeningsType: {
    allTheaterLines: "所有院線",
    allRegions: "所有地區",
    allVersions: "所有版本",
  },
  events: {
    nft: "<span>成為黑曜石會員，盡享高達30%的優惠！</span>",
    drink:
      "<span>前往 𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 幸運抽獎！奶茶咖啡飲到飽！<br><br>#𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 𝙇𝙪𝙘𝙠𝙮 𝘿𝙧𝙖𝙬 </span>",
    atghnft: "<span>#四月玩大佢<br><br>AT BIRD「不得鳥」NFT傳送門</span>",
    nftflash: "「不得鳥」NFT會員 #快閃專屬優惠",
  },

  modal: {
    pendingTransaction: "待處理交易",
    pendingTransactionDone: "已完成交易",
    pendingTransactionAmount:
      "您有一項代處理充值交易金額為 {{amount}} AT。您要繼續完成嗎？",
    pendingTransactionCompleted:
      "您的充值交易處理已完成，金額為 {{amount}} AT。",
  },
};
