import { t } from "i18next";
import React, { useState, useEffect, useMemo } from "react";
import { Container, LoadingIcon } from "src/components";
import { AtBirdCollections } from "./at-birds-collections";
import { DisplayNft } from "./display-nft";
import { useMemberStore } from "src/stores";
import { MINTING_URL } from "src/config";

export const NftBirdsCollection = (props: any) => {
  const [allNftCollections, setAllNftCollections] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [display, setDisplay] = useState({});
  const [loading, setLoading] = useState(true);

  const { getNftCollections } = useMemberStore();

  useEffect(() => {
    setLoading(true);
    getNftCollections().then((result: any) => {
      if (!result) return;
      setLoading(false);
      setAllNftCollections(result);
      setDisplay(result[0]);
    });
  }, [getNftCollections, setDisplay, setAllNftCollections]);

  const onQrClick = () => setOpenModal(true);

  const redirect = () => {
    // TODO: Redirect to Minting site if no NFT is found
    return window.open(MINTING_URL, "_blank");
  };

  const hasNft = useMemo(() => {
    if (!allNftCollections) return false;
    if (allNftCollections.length === 0) return false;
    return true;
  }, [allNftCollections]);

  if (loading) {
    return (
      <Container toPage={"/wallet"} title={t("myNftCollection")}>
        <div className="flex justify-center pt-5">
          <LoadingIcon />
        </div>
      </Container>
    );
  }

  if (!hasNft) {
    // Dummmy for empty list
    return (
      <Container toPage={"/wallet"} title={t("myNftCollection")}>
        <DisplayNft
          hasNft={hasNft}
          displayAtBird={display}
          onClickHandler={redirect}
        />
      </Container>
    );
  }

  return (
    <Container toPage={"/wallet"} title={t("myNftCollection")}>
      {/* Display AT Bird */}
      {display && (
        <DisplayNft
          hasNft={hasNft}
          displayAtBird={display}
          onClickHandler={onQrClick}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      {/* All AT Bird Collections */}
      {hasNft && allNftCollections && allNftCollections.length > 0 && (
        <AtBirdCollections
          allNftCollections={allNftCollections}
          setDisplay={setDisplay}
        />
      )}
    </Container>
  );
};
