import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import Form from "./form";

export const LoginPage = (props: any) => {
  const { t } = useTranslation();
  return (
    <Container toPage={"/"} title={t("backUp")}>
      <div className="px-5 pt-10">
        <Form />
      </div>
    </Container>
  );
};
