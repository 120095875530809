import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  BottomModal,
  openNotificationWithIcon,
  LoadingIcon,
  openWarningNotification,
} from "../../components";
import { useTranslation } from "react-i18next";
import { ExclusiveEvents } from "./exclusive-events";
import { TrendingCoupons } from "./coupons";
import {
  history,
  useCouponStore,
  useCinemaStore,
  useMyLinkStore,
  useMemberStore,
  useTransactionStore,
} from "src/stores";
import { MovieIndex } from "./movie";
import { IsLogin, SetToken } from "src/utils/token";
import { PendingOrderModal } from "./pending-order-modal";

export const Home = (props: any) => {
  const { location } = props;
  const [coupons, setCoupons] = useState(null);
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disclaimerLoading, setDisclaimerLoading] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [pendingOrder, setPendingOrder] = useState<any>({});
  const [openModal, setOpenModal] = useState<any>(false);

  const { t } = useTranslation();
  const { getCouponsV2 } = useCouponStore();
  const { getCinemaMovieList } = useCinemaStore();
  const { loginByMyLinkToken, agreeDisclaimer } = useMyLinkStore();
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  let myLinkToken = params.thirdPartyToken; // "some_value"
  if (!myLinkToken) myLinkToken = localStorage.getItem("my-link-token") || "";
  const { getUserMembershipInfo } = useMemberStore();
  const { checkPendingOrder, updatePaymentOrder } = useTransactionStore();

  useEffect(() => {
    // Login myLink if got token
    if (myLinkToken) {
      SetToken(myLinkToken, "my-link-token");
      loginByMyLinkToken({ token: myLinkToken }).then((result) => {
        if (!result) return;
        const { disclaimer } = result;
        if (!disclaimer) setShowDisclaimer(true);
      });
    }

    // Get Featured Coupons
    getCouponsV2(`?type=featured`).then((result) => {
      if (!result) return;
      setCoupons(result);
    });

    // Get Movies
    getCinemaMovieList({ filmCategory: "intraday", count: 3 }).then(
      (result) => {
        if (result) return setMovies(result.film_list);
      }
    );
    setLoading(false);
  }, [
    myLinkToken,
    loginByMyLinkToken,
    setShowDisclaimer,
    getCouponsV2,
    setCoupons,
    setLoading,
    getCinemaMovieList,
    setMovies,
  ]);

  const handleDisclaimer = async () => {
    setDisclaimerLoading(true);
    await agreeDisclaimer({
      token: myLinkToken,
    });
    setDisclaimerLoading(false);
    setShowDisclaimer(false);
    SetToken(myLinkToken, "my-link-token");
  };

  useEffect(() => {
    const hasLogin = IsLogin();
    if (!hasLogin) return;

    // Get MemberInfo - TODO: Remove in the future
    if (hasLogin) {
      getUserMembershipInfo().then((result) => {
        if (!result) return;
        setMemberInfo(result);
      });
    }

    // Get pending order
    checkPendingOrder().then((result: any) => {
      if (
        result &&
        (result?.msg === "pending_order_required" ||
          result?.msg === "pending_order_completed")
      ) {
        setPendingOrder(result);
        setOpenModal(true); // Show modal when pending transactions found
        return;
      }
      setPendingOrder({});
      setOpenModal(false);
      return;
    });
  }, [
    getUserMembershipInfo,
    setMemberInfo,
    checkPendingOrder,
    setPendingOrder,
    setOpenModal,
  ]);

  const toPageCb = () => {
    const balance = memberInfo["at_points"];
    return history.push("/top-up-atpoints", {
      balance,
    });
  };

  const handleCancel = async (paymentOrderId: string) => {
    const updated: any = await updatePaymentOrder({
      orderId: paymentOrderId,
      status: "cancelled",
    });
    updated && setOpenModal(false);
  };

  const buttonHandler = async (msg: string, payment: any, action?: string) => {
    setOpenModal(false);
    if (msg === "pending_order_required" && action === "cancel")
      return await handleCancel(payment.data.order_id);

    // Redirect to top up page and cancel order
    switch (msg) {
      case "pending_order_required":
        const { data } = payment;
        const { order_id, amount } = data;
        try {
          await handleCancel(order_id);
          return history.push("top-up-atpoints", { required: String(amount) });
        } catch (err) {
          return openWarningNotification(
            t("messages.failedToUpdatePaymentOrder")
          );
        }
      case "pending_order_completed":
        return setOpenModal(false);
      default:
        break;
    }
  };

  const modelTitle = useMemo(() => {
    if (!pendingOrder || !pendingOrder["msg"])
      return t("modal.pendingTransaction");

    switch (
      pendingOrder["msg"] // transaction_not_found
    ) {
      case "pending_order_required":
        return t("modal.pendingTransaction");
      case "pending_order_completed":
        return t("modal.pendingTransactionDone");
      default:
        break;
    }
  }, [pendingOrder, t]);

  return (
    <>
      {/* Pending Order Modal */}
      <PendingOrderModal
        title={modelTitle}
        open={openModal}
        buttonHandler={buttonHandler}
        pendingOrder={pendingOrder}
        closable={false}
        onCancel={() => setOpenModal(false)}
      />
      <Container
        from="home"
        toPageCb={toPageCb}
        hasFooter={true}
        title={t("explore")}
        showExchange={true}
      >
        <ExclusiveEvents />

        <TrendingCoupons coupons={coupons} loading={loading || !coupons} />
        <MovieIndex movies={movies} loading={loading} location={location} />

        <BottomModal
          open={showDisclaimer}
          setOpen={setShowDisclaimer}
          spaceDiscard={true}
        >
          <div className="h-[30vh] flex-col p-10">
            <h5>{t("disclaimer.myLink")}</h5>
            {disclaimerLoading && (
              <div className="flex justify-center pt-5">
                <LoadingIcon />
              </div>
            )}
            {!disclaimerLoading && (
              <div className="flex-col  mt-10">
                <button
                  className="primacy py-2 mb-2 flex-1"
                  onClick={() => handleDisclaimer()}
                >
                  {t("disclaimer.agree")}
                </button>
                <button
                  className="secondary py-2 mb-2 flex-1 mt-2"
                  onClick={() => {
                    openNotificationWithIcon(
                      "info",
                      t("messages.comingSoon"),
                      t("messages.disclaimer")
                    );
                  }}
                >
                  {t("disclaimer.discard")}
                </button>
              </div>
            )}
          </div>
        </BottomModal>
      </Container>
    </>
  );
};
