import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { useCouponStore } from "src/stores";
import { Coupons } from "./coupons";

export const NftExclusiveFlashZone = (props: any) => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { getCoupons } = useCouponStore();

  useEffect(() => {
    setLoading(true);
    getCoupons("?member_tier_required=4").then((result) => {
      setLoading(false);
      if (result) return setCoupons(result);
    });
  }, [getCoupons, setCoupons, setLoading]);
  return (
    <Container toPage={"/"} title={t("coupon")}>
      <Coupons
        coupons={coupons}
        loading={loading}
        from="nft-exclusive-flash-sale"
      />
    </Container>
  );
};
