import React from "react";

// const BYON_PURPLE = "#020815";
export const ByonNavigationBar = (props: any) => {
  return (
    <div
      className={`bg-[#020815] min-h-[44px] w-full flex justfy-center items-center p-[30px]`}
    >
      <img
        src="/assets/byon_logo.png"
        alt="byon-logo"
        className="w-[100px] mx-auto object-fit"
      />
    </div>
  );
};
