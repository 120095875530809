import { t } from "i18next";
import React from "react";

export const Card = (props: any) => {
  const { data, type } = props;

  const getBg = (type: string, data: any) => {
    switch (type) {
      case "membership":
        if (data.tier === "OBSIDIAN") return "/assets/obsidian.jpg";
        return "/assets/jade.jpg";
      case "stat":
      default:
        return "/assets/stat.jpg";
    }
  };

  const getSubTitle = (type: string) => {
    switch (type) {
      case "membership":
        return t("card.tier");
      case "stat":
      default:
        return t("card.graz");
    }
  };

  const getMainTxt = (type: string, data: any) => {
    switch (type) {
      case "membership":
        return t(`card.${data.tier}`);
      case "stat":
      default:
        return t("card.stat");
    }
  };

  return (
    <div
      className={`min-h-[80px] relative drop-shadow-lg w-full border-[1px] p-3 rounded-2xl bg-cover text-white bg-top`}
      style={{ backgroundImage: `url(${getBg(type, data)})` }}
    >
      <p className="text-white text-[10px]">{getSubTitle(type)}</p>

      {data && data.tier && (
        <p
          className={`text-white font-bold ${
            type === "stat" ? "text-[14px] pb-[0px]" : "text-lg pb-3"
          }`}
        >
          {getMainTxt(type, data)}
        </p>
      )}

      {type === "membership" && (
        <div className="w-5 h-5 overflow-hidden absolute top-2 right-2">
          <img
            src="/assets/AT_logo_white.png"
            alt="at-logo-white"
            className="w-full h-full object-cover"
          />
        </div>
      )}
    </div>
  );
};
