import React from "react";
import { useTranslation } from "react-i18next";

export const OpenGcModal = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="p-[30px] pb-[200px]">
      <h5 className="text-left text-black text-[32px]">
        {t("services.verification")}
      </h5>

      <div className="pt-[30px] text-[14px] text-black">
        {t("services.verifyNote")}
      </div>
    </div>
  );
};
