import React from "react";

export const ByonBanner = (props: any) => {
  return (
    <div className="py-3 w-full bg-[#393939] flex flex-row items-center justify-center">
      {/* <p className="text-[#FFCD38] mr-2">Powered by</p> */}
      <div className="w-[80px]">
        <img
          src="/assets/logo_byon_white.png"
          alt="mallqs"
          className="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  );
};
