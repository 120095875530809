import { t } from "i18next";
import React from "react";
import { getName } from "src/utils";

export const TopUpSummary = (props: any) => {
  const { selectedPayment, topupAtpAmount, paymentOrderId = null } = props;

  const renderRow = (title: string, value: string) => {
    let customStyle = {};

    switch (title) {
      case "topupAmount":
        customStyle = "text-at-pink";
        break;
      case "atPoints":
        customStyle = "text-[#60BE38]";
        break;
      default:
        customStyle = "text-black";
        break;
    }

    return (
      <div
        className={`flex justify-between ${
          title !== "orderNumber" && `pb-[15px]`
        }`}
      >
        <p className="text-left font-bold">{t(title)}</p>
        <p className={`text-right font-bold ${customStyle}`}>{value}</p>
      </div>
    );
  };

  return (
    <div className="w-full px-2">
      {/* Top up AT Amount */}
      {renderRow("topupAmount", `-${topupAtpAmount}`)}

      {/* Receive AT */}
      {renderRow("atPoints", `+${topupAtpAmount}`)}

      {/* Top Up Method */}
      {renderRow("topupMethod", getName(selectedPayment))}

      {/* Payment Order Id */}
      {paymentOrderId && renderRow("orderNumber", paymentOrderId)}
    </div>
  );
};
