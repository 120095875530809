import { t } from "i18next";
import React from "react";
import { openErrNotification } from "src/components";
import { history } from "src/stores";

export const NftBirdCollections = (props: any) => {
  const { user } = props;

  const handleNftCollection = () => {
    if (!user || !user.wallet_address)
      return openErrNotification(t("nft.noWalletAddress"));
    return history.push("/nft-collection");
  };

  return (
    <div className="relative z-5 w-auto" onClick={handleNftCollection}>
      <div className="fixed right-[20px] bottom-[225px]">
        <button className="px-3 py-1 rounded-full drop-shadow !border-none">
          <p className="text-[10px] px-1 rounded-full bg-pink text-white ">
            {t(`button.nftCollection`)}
          </p>
        </button>
      </div>

      <div className="fixed right-[-48px] bottom-[15px]">
        <img
          src={"/assets/at-bird.png"}
          alt="at-bird"
          className="w-[250px] h-[250px] object-cover"
        />
      </div>
    </div>
  );
};
