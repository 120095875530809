import React, { useEffect, useState } from "react";
import { openWarningNotification } from "../../components";
import { useTranslation } from "react-i18next";
import { useAuthStore, useCardStore, useTransactionStore } from "src/stores";
import QrReader from "react-web-qr-reader";
import { ByonNavigationBar } from "src/components/byon-navigation-bar";
import { history } from "src/stores";
const DELAY = 1000;
export const ByonServiceScan = (props: any) => {
  const { payToMerchant } = useTransactionStore();
  const { access } = useAuthStore();
  const { getCardInfo } = useCardStore();
  const [login, setLogin] = useState<boolean>(false);
  const [card, setCard] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  const countryCode = params["country_code"];
  const phoneNum = params["phone_num"];

  useEffect(() => {
    if (login) return;
    if (!countryCode || !phoneNum) return;
    access({
      phoneNum,
      countryCode,
      otp: "KY5fpkD8szo^sF11q*t6DEeWoQlBfNeM",
    }).then((result) => {
      if (!result)
        return openWarningNotification(t("messages.failedToVerifyUser"));

      setLogin(true);
    });
  }, [login, countryCode, phoneNum, access, t]);

  useEffect(() => {
    if (!login) return;
    setLoading(true);
    getCardInfo(`?card_service=gc&service=at_33`).then((item) => {
      setLoading(false);
      if (!item || !item["card_number"]) {
        setCard(null);
        history.push("/byon-service");
        return;
      }
      setCard(item);
    });
  }, [login, setCard, getCardInfo]);

  // Testing - Do not delete
  // useEffect(() => {
  //   if (loading || !card || !login) return;
  //   setTimeout(() => {
  //     handleScan({
  //       data: "00020101021226360012hk.com.hkicl01039300209799084959520400005303344540413.65802HK5902NA6002HK62280124000000000004000002301939630497A7",
  //     });
  //   }, 5000);
  // }, [loading, card, login]);

  const handleScan = async (value: any) => {
    if (loading) return openWarningNotification(t("button.loading"));
    if (!login || !card)
      return openWarningNotification(t("messages.failedToVerifyUser"));
    const data = value.data;
    if (!data) return;

    // Pay to Merchant
    const payload = {
      card_no: card.card_number,
      qrc_data: data,
      return_url: `${window.location.origin}/byon-service-scan?phone_num=${phoneNum}&country_code=${countryCode}`,
      card_service: "gc",
    };
    const transaction = await payToMerchant(payload);
    if (!transaction) return openWarningNotification(t("messages.cannotPay"));

    const url = transaction["response_detail"]["redirect_url"];
    if (!url) return openWarningNotification(t("messages.cannotPay"));
    window.open(url, "_self");
  };

  const handleError = (error: any) => {
    console.log("Error in handling QR Scan", error);
  };

  if (!QrReader) return null;
  return (
    <div className="relative bg-[#020815] min-h-screen">
      {/* Nav */}
      <div className="z-10 relative">
        <ByonNavigationBar />
      </div>

      {/* Scanner */}
      <div className="w-full flex flex-col mt-5 flex-1 bg-[#020815] h-full relative left-0 ">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <QrReader
          onError={(e: any) => handleError(e)}
          onScan={(e: any) => handleScan(e)}
          className="qr-reader"
          style={{ width: "100%", position: "relative", zIndex: 10 }}
          delay={DELAY}
        />
      </div>

      {/* BG gradient */}
      <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full z-0">
        <img
          src="/assets/byon_bg.png"
          className="w-full h-full object-fit"
          alt="bg"
        />
      </div>

      {/* Wave */}
      <div className="absolute bottom-0 left-0 w-full z-0">
        <img
          src="/assets/byon_wave.png"
          className="w-full object-fit"
          alt="wave"
        />
      </div>
    </div>
  );
};
