import React from "react";
import { useTranslation } from "react-i18next";
import { BYON_URL } from "src/config";

const tbodyTdStyle = `text-left p-2 min-w-[80px]`;
const theadThStyle = `text-left p-2 `;

export const ConfirmToByon = (props: any) => {
  const { type, setOpen } = props;
  const { t } = useTranslation();

  const byonAction = (type: string) => {
    setOpen(false);
    return window.open(
      `${BYON_URL}/tc/form-microloan.html?promo=${type.toUpperCase()}`
    );
  };

  const getRepayment = (type: string, amount: number) => {
    switch (amount) {
      case 50000:
        if (type === "at33") return "HKD $16,862";
        if (type === "at66") return "HKD $8,529";
        break;
      case 75000:
        if (type === "at33") return "HKD $25,293";
        if (type === "at66") return "HKD $12,793";
        break;
      case 100000:
        if (type === "at33") return "HKD $33,724";
        if (type === "at66") return "HKD $17,057";
        break;
      default:
        return "";
    }
  };

  return (
    <div className="p-5 pb-10 flex flex-col text-center min-h-[300px] mx-auto">
      <h5 className="text-center">
        {t("services.openService", { type: type === "at33" ? "3+3" : "6+6" })}
      </h5>

      <div className="mt-10">
        <table className="border-collapse border border-slate-500">
          <thead>
            <tr>
              <th></th>
              <th className={theadThStyle}>HKD $50,000</th>
              <th className={theadThStyle}>HKD $75,000</th>
              <th className={theadThStyle}>HKD $100,000</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className={`${tbodyTdStyle} min-w-[auto]`}>
                {t("services.paymentDesc")}
              </td>
              <td className={tbodyTdStyle}>{getRepayment(type, 50000)}</td>
              <td className={tbodyTdStyle}>{getRepayment(type, 75000)}</td>
              <td className={tbodyTdStyle}>{getRepayment(type, 100000)}</td>
            </tr>
          </tbody>
        </table>
        <p className="pt-5 text-black">
          {type === "at33"
            ? t("services.paymentNote33")
            : t("services.paymentNote66")}
        </p>
        <p className="pt-5 pb-10 text-left text-[10px]">
          {t("services.extraNote")}
        </p>
      </div>

      <div className="flex flex-1 justify-center items-end">
        <button className="primary full py-3" onClick={() => byonAction(type)}>
          {t("services.toByonOpen")}
        </button>
      </div>
    </div>
  );
};
