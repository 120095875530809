import React from "react";
import { useTranslation } from "react-i18next";
import { history } from "src/stores";
import { Coupons } from "../coupons-home/coupons";

export const TrendingCoupons = (props: any) => {
  const { coupons, loading } = props;
  const { t } = useTranslation();
  return (
    <div className="mt-1 bg-transparent overflow-hidden overflow-x-scroll scroll-px-5 snap-x ">
      <div className="flex flex-row px-5 pt-5">
        <h5 className="flex flex-1">{t("trendingCoupons")}</h5>
        <button onClick={() => history.push("/coupons-home")}>
          {t("all")}
          {t("coupon")}
        </button>
      </div>

      {<Coupons coupons={coupons} loading={loading} />}
    </div>
  );
};
