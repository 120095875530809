import React from "react";
import { t } from "i18next";
const { Modal } = require("antd");

export const CustomModal = (props: any) => {
  const {
    children,
    centered,
    open,
    title,
    okText,
    onCancel,
    cancelText,
    width,
    height,
    content,
    handleOk,
    maskClosable = false,
    destroyOnClose = false,
    closable = false,
    topButtonTxt,
    bottomButtonTxt,
    handleConfirm,
    handleCancel,
  } = props;

  const buttonStyle = "text-[10px] w-auto mx-1 py-[8px]";
  return (
    <Modal
      centered={centered}
      visible={open}
      title={title}
      okText={okText}
      cancelText={cancelText}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      closable={closable}
      onOk={(e: any) => handleOk(e)}
      onCancel={onCancel}
      header={null}
      width={width}
      height={height}
      footer={false}
      className={"pending-order-modal"}
    >
      {content}
      {children}
      <div className="flex flex-col justify-center rounded-full gap-2 mt-5 mb-2 mx-7">
        <button className={`${buttonStyle}`} onClick={() => handleConfirm()}>
          {t(`button.${topButtonTxt}`)}
        </button>
        <button
          className={`${buttonStyle} secondary`}
          onClick={() => handleCancel()}
        >
          {t(`button.${bottomButtonTxt}`)}
        </button>
      </div>
    </Modal>
  );
};
