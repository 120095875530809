import { t } from "i18next";
import React from "react";

export const HowToUse = (props: any) => {
  const { data = [] } = props;

  return (
    <div className="flex flex-col self-start">
      <div className="flex flex-row">
        <h5>{t("ticket.howToUse")}</h5>
      </div>

      {data.length > 0 &&
        data.map((item: any, i: any) => (
          <div className="pt-2 first:pt-5" key={i}>
            <p className="text-[10px]">
              {String(i + 1)}. {item}
            </p>
          </div>
        ))}
    </div>
  );
};
