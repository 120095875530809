import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Container } from "src/components";
import { ExclusiveEvents } from "../home/exclusive-events";
// import { member } from "../sample-data/member";
import { QuickIcons } from "./quick-icons";
import { ITEMS_ONE, ITEMS_TWO } from "./quick-icons-one";
import { NftBirdCollections } from "../home/nft-bird-collections";
import { history, useMemberStore, useMyLinkStore } from "src/stores";
import { GetToken } from "src/utils/token";

export const WalletHome = (props: any) => {
  const { t } = useTranslation();
  const [memberInfo, setMemberInfo] = useState({
    nickname: "Loading...",
    avatar: null,
  });
  const { getUserMembershipInfo } = useMemberStore();
  const { getMyLinkUserPoints } = useMyLinkStore();

  useEffect(() => {
    let userInfo: any = {};
    const myLinkToken = GetToken("my-link-token");
    // Get My Link Points
    if (myLinkToken) {
      getMyLinkUserPoints(myLinkToken).then((result: any) => {
        if (!result) return;
        userInfo = { my_link_points: result.userPointAvailable };
      });
    }

    // Get Memebership Info
    getUserMembershipInfo().then((result) => {
      if (!result) return;
      userInfo = {
        ...userInfo,
        ...result,
      };
      setMemberInfo(userInfo);
    });
  }, [getMyLinkUserPoints, getUserMembershipInfo, setMemberInfo]);

  return (
    <Container
      // avatar={memberInfo.avatar || member.avatar}
      hasFooter={true}
      title={memberInfo.nickname}
    >
      <div className="bg-white drop-shadow-md pb-5 relative">
        <button
          className="mt-2 py-0.5 ml-5 secondary z-50 relative"
          onClick={() => {
            history.push("/edit-profile", { user: memberInfo });
          }}
        >
          {t("button.editProfile")}
        </button>

        <div className="px-5 grid grid-cols-2 pt-7 gap-5">
          <div className="w-full">
            <Card type="membership" data={memberInfo} />
          </div>

          <div className="w-full">
            <Card type="stat" data={memberInfo} />
          </div>
        </div>
      </div>

      {/* Quck Icons (1) */}
      <QuickIcons data={ITEMS_ONE} user={memberInfo} />

      {/* Quck Icons (2) */}
      <QuickIcons data={ITEMS_TWO} user={memberInfo} />

      <div className="mt-5">
        <ExclusiveEvents />
      </div>

      <NftBirdCollections user={memberInfo} />
    </Container>
  );
};
