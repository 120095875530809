import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { history } from "src/stores";
import { LoadingIcon } from "src/components";
import * as Utils from "src/utils";

export const MovieShowingTimeList = (props: any) => {
  const {
    location,
    loading = false,
    chooseDate,
    cinemaShowingTimeList,
  } = props;
  const { filmId } = location.state;
  const { t } = useTranslation();
  const [isEmptyFilmShow, setIsEmptyFilmShow] = useState(true);

  useEffect(() => {
    let filmShowLen =
      cinemaShowingTimeList.length > 0
        ? cinemaShowingTimeList[0].film_show.length
        : 0;
    filmShowLen ? setIsEmptyFilmShow(false) : setIsEmptyFilmShow(true);
  }, [cinemaShowingTimeList, isEmptyFilmShow]);

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  return (
    <>
      {isLoading && (
        <div className="flex justify-center py-5 absolute w-full pb-12 top-72">
          <LoadingIcon />
        </div>
      )}
      {!isLoading && isEmptyFilmShow && (
        <div className="flex justify-center py-5 absolute w-full pb-12 top-72">
          <p>{t("noMovieShowingTime")}</p>
        </div>
      )}
      {!isLoading &&
        !isEmptyFilmShow &&
        cinemaShowingTimeList.map((itme: any, i: number) => {
          return (
            <div key={i} className="relative w-full">
              <div className="bg-white drop-shadow-md p-5 mt-3">
                <div className="grid grid-cols-2 gap-5 h-4">
                  <span className="col-span-1 text-base text-pink overflow-x-scroll h-5">
                    {itme.cinema_name}
                  </span>
                  <span className="col-span-1 text-xs text-gray-400 text-right overflow-x-scroll h-4">
                    {Utils.getArrTypeToStr(itme.film_show)}
                  </span>
                </div>
                <div className="text-xs mt-1">
                  {itme.film_show &&
                    itme.film_show.map((itmes: any, t: number) => {
                      return (
                        <button
                          key={t}
                          className="mt-2.5 py-0.5 mr-2 w-auto secondary text-xs font-thin border-green-500"
                          onClick={() =>
                            history.push("/movie-showing-detail", {
                              param: {
                                filmId: filmId,
                                cinemaId: itme.cinema_id,
                                filmShowId: itmes.id,
                                date: chooseDate,
                              },
                              prevPath: location.pathname,
                            })
                          }
                        >
                          {itmes.time}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
