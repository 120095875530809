import React from 'react';
import { t } from "i18next";
import { Container } from 'src/components';
import { ReferralForm } from './from'
import { ReferralRemark } from './remark';

const promoTexts = [t("referral.promo1"), t("referral.promo2")];
export const Referral = (props: any) => {
  const { location } = props;
  const { user } = location.state || {};

  return (
    <Container toPage={"/wallet"} title={t("referralProgram")}>
      <div className="px-5 pt-10 ">
        <ReferralForm user={user} />
      </div>

      <ReferralRemark texts={promoTexts} />
      {/* <div className="h-1/4 w-screen bg-contain bg-no-repeat bg-bottom bg-[url('/assets/at-bird-pink.png')] absolute bottom-0"></div> */}
    </Container>
  )
}