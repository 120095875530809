import React, { useEffect } from "react";
import { Container } from "../../components";
import { useTranslation } from "react-i18next";
import { history } from "src/stores";

export const ServiceDetail = (props: any) => {
  const { t } = useTranslation();
  const { location } = props;
  const service = (location.state && location.state.service) || null;

  useEffect(() => {
    if (!service) return history.push("/service");
  }, [service]);

  return (
    <Container
      hasFooter={false}
      title={`${service.name} ${t("service")}`}
      toPage={`/service`}
    >
      {/* Info */}
      <div className="relative w-full bg-white flex flex-col px-5 py-5 items-center ">
        <div className="text-center">
          <div className="w-[60px] h-[60px] aspect-w-1 aspect-h-1 overflow-hidden rounded-full bg-white relative drop-shadow-md border">
            <img
              src={service.img}
              alt={service.merchant}
              className=" w-full h-full object-contain"
            />
          </div>
          <p className="mt-3">{service.name}</p>
        </div>
        <p className="pt-5 text-center">{service.desc}</p>
      </div>

      {/* How To Use */}
      <div className="relative w-full bg-white flex flex-col px-5 py-5 mt-5">
        <h5>{t("services.howToUse")}</h5>
        <p className="pt-2">{t("services.scanToUse")}</p>
      </div>

      {/* T & C */}
      <div className="relative w-full bg-white flex flex-col px-5 py-5 mt-5">
        <h5>{t("ticket.tnc")}</h5>
        <p className="pt-2">{service.desc}</p>
      </div>

      {/* Button to use */}
      <div
        className="fixed top-[90%] px-8 w-screen"
        onClick={() => history.push("/service-use", { service })}
      >
        <button className="full py-3 text-xs">{t(`ticket.use`)}</button>
      </div>
    </Container>
  );
};
