import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, SmallTicket } from "src/components";
import { history } from "src/stores";

export const CouponPurchased = (props: any) => {
  const { location } = props;
  let { coupon } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    if (!location.state.coupon) return history.push("/coupons-home");
  }, [location.state.coupon]);

  return (
    <Container title={`${t("coupon")}${t("purchased")}`}>
      {coupon && (
        <div className="px-5 py-5">
          <SmallTicket
            id={coupon.id}
            couponId={coupon.coupon_id}
            couponCode={coupon.coupon_code}
            couponTitle={coupon.couponTitle}
            couponTitleEn={coupon.couponTitleEn}
            couponIcon={coupon.couponIcon}
            amount={coupon.amount}
            expirationDate={coupon.expirationDate}
            redemption_type={coupon.redemption_type}
            type={coupon.type}
          />
        </div>
      )}

      <div className="flex flex-col items-center">
        <div className="mt-5 w-32 h-32 overflow-hidden ml-4">
          <img
            src={"/assets/location.png"}
            alt={"coffee-icon"}
            className=" w-full h-full object-cover"
          />
        </div>

        <button
          className="mt-5 py-2 mb-2 w-[150px]"
          onClick={() => history.push("/wallet")}
        >
          {t("button.returnToWallet")}
        </button>

        <button
          className="mt-1 py-2 mb-2 w-[150px] secondary"
          onClick={() => history.push("/coupons-home")}
        >
          {t("button.continueExploring")}
        </button>
      </div>
    </Container>
  );
};
