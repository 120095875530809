import React, { useEffect, useMemo, useState } from "react";
import { BottomModal, Container, LoadingIcon } from "../../components";
import { useTranslation } from "react-i18next";
import { history, useCardStore } from "src/stores";
import { formatDollar } from "src/utils";
import { Card } from "./card";
import { CardInfo } from "./card-info";
import { OpenGcModal } from "./open-gc-modal";

export const ServiceHome = (props: any) => {
  const { t } = useTranslation();
  const { getCardInfo } = useCardStore();
  const [card, setCard] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getCardInfo(`?card_service=gc&service=at_33`).then((item) => {
      setLoading(false);
      if (!item || !item["card_number"]) return setCard(null);
      setCard(item);
    });
  }, [setCard, getCardInfo]);

  const balance = useMemo(() => {
    if (!card || !card["balance"]) return 0;
    const balance = parseFloat(card.balance) / 100;
    return formatDollar(balance);
  }, [card]);

  const getBtnName = () => {
    if (loading) return <LoadingIcon size={20} color={"white"} />;
    if (card) return t("services.topup");
    return t("services.activate");
  };

  return (
    <Container hasFooter={true} title={`${t("service")}`}>
      <div className="mt-10 px-[30px]">
        {/* Card */}
        <Card loading={loading} card={card} balance={balance} />

        {/* Scan to pay */}
        {card && (
          <>
            <div>
              <button
                className={`mt-5 primary full !px-5 text-xs !py-3`}
                onClick={() =>
                  history.push("/service-scan", {
                    cardNumber: card.card_number,
                  })
                }
              >
                {t("services.scanToPay")}
              </button>
            </div>

            <div className="mt-[10px]">
              <p
                className="text-at-pink cursor-pointer text-center font-[900]"
                onClick={() => history.push("/service-top-up")}
              >
                {getBtnName()}
              </p>
            </div>
          </>
        )}

        {!card && (
          <div>
            <button
              className={`mt-5 primary full !px-5 text-xs !py-3 flex justify-center`}
              onClick={() => history.push("/service-top-up")}
            >
              {getBtnName()}
            </button>
          </div>
        )}
      </div>

      {/* Available Merchants */}
      {/* <AvailableMerchants card={card} /> */}

      {/* Card Info */}
      {card && <CardInfo loading={loading} card={card} setOpen={setOpen} />}

      {/* Pop Up Modal */}
      <BottomModal open={open} setOpen={setOpen}>
        <OpenGcModal />
      </BottomModal>
    </Container>
  );
};
