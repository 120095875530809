import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class TransactionStore {
  async checkPaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/check-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async createPaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/create-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async checkPendingOrder() {
    const url = `${Configs.apiUrl}/payment/check-pending-order`;
    try {
      const result: any = await axios.post(url, {}, await setHeader());
      if (!isResultSuccess(result)) return null;
      refreshNewToken(result);
      return result["data"];
    } catch (e) {
      return null;
    }
  }

  async payToMerchant(payload: any) {
    const url = `${Configs.apiUrl}/merchants/create-payment`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updatePaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/update-order`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_TRANSACTION = "transactionStore";
