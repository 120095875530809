import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { openSuccessNotification } from "src/components";
import { t } from "i18next";
import { history } from ".";
import { errorHandling } from "./api";
export class AuthStore {
  async getOtpCode(phoneNum: string, countryCode: string) {
    const url = `${Configs.apiUrl}/auth/get-otp`;
    try {
      const result: any = await axios.post(url, { phoneNum, countryCode });
      if (!result || !result["data"]) return generalError;
      return result["data"];
    } catch (e) {
      return generalError;
    }
  }

  async access(payload: any) {
    const url = `${Configs.apiUrl}/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result["data"]) return generalError;
      if (!result["data"]["success"]) return generalError;

      const jwt = result["data"]["data"]["jwt"];
      localStorage.setItem("at-token", jwt);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  logout() {
    openSuccessNotification(t("messages.logout"));
    localStorage.removeItem("at-token");
    history.push("/");
    return;
  }
}

export const STORE_AUTH = "authStore";
