import React, { useEffect, useState } from "react";
import { MovieShowingTimeList } from "./movie-showing-time-list";
import { Container } from "src/components";
import { useCinemaStore } from "src/stores";
import { history } from "src/stores";
import * as Utils from "src/utils";

export const MovieShowingTimeIndex = (props: any) => {
  const { location } = props;
  const { filmId } = location.state;
  const param = { filmId: filmId, date: "" } || {};

  const [loading, setLoading] = useState(true);
  const [filmDetail, setFilmDetail] = useState<any>([]);
  const [chooseDate, setChooseDate] = useState<string>("");
  const [cinemaShowingDateList, setCinemaShowingDateList] = useState<any>([]);
  const [cinemaShowingTimeList, setCinemaShowingTimeList] = useState<any>([]);
  const [filmTypeList, setfilmTypeList] = useState<any>([]);
  const [searchParam, setSearchParam] = useState(param);
  const { getMovieShowingDateList, getMovieShowingCinemaList } =
    useCinemaStore();

  useEffect(() => {
    getMovieShowingDateList({ filmId: filmId }).then((result) => {
      setLoading(false);
      setSearchParam((searchParam) => {
        searchParam.date = result.showDate[0];
        return { ...searchParam };
      });
      setChooseDate(result.showDate[0]);
      if (result.filmType.length > 0) setfilmTypeList(result.filmType);
      if (result.showDate.length > 0) setCinemaShowingDateList(result.showDate);
    });
  }, [
    filmId,
    getMovieShowingDateList,
    setSearchParam,
    setChooseDate,
    setLoading,
  ]);

  useEffect(() => {
    setLoading(true);
    if (searchParam.date) {
      getMovieShowingCinemaList(searchParam).then((result) => {
        setLoading(false);
        if (result.filmDetail) setFilmDetail(result.filmDetail);
        if (result.filmDetail.cinema_list)
          return setCinemaShowingTimeList(result.filmDetail.cinema_list);
      });
    }
  }, [
    searchParam,
    getMovieShowingCinemaList,
    setCinemaShowingTimeList,
    setFilmDetail,
    setLoading,
  ]);

  const hasChooseDate = (e: any) => {
    if (e && e === chooseDate) return;
    setChooseDate(e);
    setSearchParam((searchParam) => {
      searchParam.date = e;
      return { ...searchParam };
    });
  };

  return (
    <Container toPage={() => history.goBack()} title={filmDetail.title}>
      <div className="fixed z-50 w-full h-28 bg-white">
        <div className="py-2 bg-white fixed w-full drop-shadow-md overflow-x-auto scroll-px-4 snap-x scroll-smooth">
          <div className="ml-2 py-3 pb-3 flex w-full">
            {filmTypeList.map((item: any, i: number) => {
              return (
                <button key={i} className="p-px font-thin rounded-full mr-2">
                  <span className="block text-xs px-2 py-0.5 rounded-full bg-white text-pink overflow-hidden max-h-5">
                    {item.name}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <div className="h-16 top-44 fixed justify-end w-full bg-gray-50 drop-shadow-md overflow-x-auto scroll-px-4 snap-x scroll-smooth">
          <div className="ml-2 py-4 flex">
            {cinemaShowingDateList.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className={`pr-1 text-center ${
                    chooseDate === item ? "text-pink font-semibold" : ""
                  }`}
                  onClick={() => {
                    hasChooseDate(item);
                  }}
                >
                  <div> {Utils.getWeek(item)}</div>
                  <div className="w-16">{Utils.getMomentTransition(item)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="relative top-[120px]">
        <MovieShowingTimeList
          cinemaShowingTimeList={cinemaShowingTimeList}
          loading={loading}
          location={location}
          chooseDate={chooseDate}
        />
      </div>
    </Container>
  );
};
export * from "./movie-showing-detail";
