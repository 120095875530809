import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BottomModal } from "src/components";
import { services_at33, services_at66 } from "./services/at-byon";
import { ConfirmToByon } from "./confirm-to-byon";
import { BYON_URL } from "src/config";

export const AtByonWallet = (props: any) => {
  const { type } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const services = type === "at33" ? services_at33 : services_at66;

  const getBtnName = () => {
    return t("services.toByonOpen");
  };

  const byonAction = () => {
    return window.open(
      `${BYON_URL}/tc/form-microloan.html?promo=${type.toUpperCase()}`
    );
  };

  const redirect = (item: any) => {
    // history.push("/service-detail", { service: item });
  };
  return (
    <div className="drop-shadow-md">
      <div className="bg-white pb-5 px-6 pt-5 relative h-full">
        <h5 className="text-left text-[12px]">
          {t("services.serviceTitle", {
            type: type === "at33" ? "3+3" : "6+6",
          })}
        </h5>
        <p className="mt-2 text-[14px] text-slate-400">
          {t(`services.serviceDesc.${type}`)}
        </p>

        {/* Avalable Services */}
        <div className="pt-10">
          <p className="font-black text-black bottom-1.5 relative">
            {t("services.availableMerchants")}
          </p>

          <div className="overflow-hidden overflow-x-scroll scroll-px-5 snap-x pt-1.5 flex flex-row">
            {services.map((item, i) => {
              return (
                <div
                  className="flex flex-col w-[50px] items-center cursor-pointer mr-5"
                  key={i}
                  onClick={() => redirect(item)}
                >
                  <div className="w-[40px] h-[40px] aspect-w-1 aspect-h-1 overflow-hidden rounded-full bg-white relative drop-shadow-md border">
                    <img
                      src={item.img}
                      alt={item.merchant}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <p className="mt-1.5">{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Button */}
        <div>
          <button
            className={`mt-5 primary full !px-5 small !py-2`}
            onClick={() => byonAction()}
          >
            {getBtnName()}
          </button>
        </div>
      </div>

      <BottomModal setOpen={setOpen} open={open}>
        <ConfirmToByon setOpen={setOpen} type={type} />
      </BottomModal>
    </div>
  );
};
