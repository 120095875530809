import React from "react";
import { useTranslation } from "react-i18next";
import { SAMPLE_EVENTS } from "../sample-data/exclusive-events";
import { history } from "src/stores";

export const ExclusiveEvents = (props: any) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-white drop-shadow-md p-5 overflow-hidden overflow-x-scroll scroll-px-5 snap-x ">
        <h5>{t("exclusiveEvents")}</h5>

        <div className="pt-5 flex flex-row flex-nowrap w-fit ">
          {SAMPLE_EVENTS.map((item: any, i) => {
            return (
              <div
                className="snap-center w-[150px] h-[190px] overflow-hidden rounded-2xl mr-5 last:mr-0 relative"
                key={i}
                onClick={
                  item.url
                    ? () => window.open(item.url)
                    : () => history.push(item.page)
                }
              >
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="w-full h-full object-cover"
                />

                {/* Opacity */}
                <div className="bg-gradient-to-b from-transparent to-slate-900/90 w-full h-[80%] absolute bottom-0 left-0" />

                {/* Text */}
                <p
                  dangerouslySetInnerHTML={{ __html: t(item.name) }}
                  className="whitespace-pre-line text-white font-bold absolute bottom-3 px-3 w-full"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
