import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { history, useCouponStore } from "src/stores";
import QRCode from "react-qr-code";
const { Popconfirm } = require("antd");
const Barcode = require("react-barcode");

const inputStyle =
  "font-bold text-black block w-full px-3 py-2 bg-white border text-sm placeholder-black border-primary focus:outline-none cursor-pointer";

export const CouponUse = (props: any) => {
  const { location } = props;
  let { coupon } = location.state;
  const [loading, setLoading] = useState(false);
  const [qrId, setQrId] = useState(null);
  const { redeemCoupon, redeemMezzofyCoupon } = useCouponStore();
  const { t } = useTranslation();
  const { type, couponId } = coupon;

  useEffect(() => {
    if (!coupon) return history.push("/coupons-home");
  }, [coupon]);

  const couponType = coupon.redemption_type || "qr_code";
  const showIcon = couponType !== "url" ? true : false;
  const iconImg = couponType === "qr_code" ? "qr.png" : "barcode.png";

  const couponSupplies = useMemo(() => {
    if (!coupon || coupon.couponSupplies.length === 0) return [];
    return coupon["couponSupplies"].sort(
      (a: any, b: any) => a.is_used - b.is_used
    );
  }, [coupon]);

  const copyToClipboard = (value: string) => {
    const element = document.createElement("textarea");
    element.value = value;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    openSuccessNotification(t("referral.copied"));
    document.body.removeChild(element);
  };

  const confirm = async (e: React.MouseEvent<HTMLElement>, item: any) => {
    if (loading) return;
    setLoading(true);
    if (!type) {
      const isCouponRedeemed = await redeemCoupon({
        coupon_code: item["coupon_code"],
        coupon_supply_id: item["_id"],
      });
      if (!isCouponRedeemed)
        return openWarningNotification(t("messages.failedToRedeemCoupon"));
    }

    if (type && type === "mezzofy") {
      const isMezzofyCouponRedeemed = await redeemMezzofyCoupon({
        coupon_id: couponId,
        coupon_code: item["coupon_code"],
      });
      if (!isMezzofyCouponRedeemed)
        return openWarningNotification(t("messages.failedToRedeemCoupon"));
    }

    openSuccessNotification(t("messages.redeemedCouponSuccess"));
    setLoading(false);
    history.push("/my-coupons");
  };

  return (
    <Container title={`${t("use")}${t("coupon")}`} toPage={"/my-coupons"}>
      <div className="container mx-auto px-[10px] pt-10">
        <p className="pb-5 text-black">{t("clickToCopy")}</p>
        {/* Redeem Coupon Defails Infomation */}
        {couponSupplies.map((item: any, i: number) => {
          const isUsed = item.is_used;
          return (
            <div key={i} className="pb-5 relative">
              <input
                className={`${inputStyle} ${
                  isUsed && "text-primary cursor-not-allowed"
                }`}
                value={item && item.coupon_code}
                readOnly
                onClick={() => !isUsed && copyToClipboard(item.coupon_code)}
              />

              {/* Redeem Url */}
              {!showIcon && (
                <p
                  onClick={() => window.open(item.coupon_code)}
                  className="absolute right-[20px] top-[11px] drop-shadow-lg !font-[900] text-[#EA437A] cursor-pointer"
                >
                  {t("redeemNow")}
                </p>
              )}

              {/* QR Code + Barcode Icon */}
              {showIcon && !isUsed && (
                <img
                  src={`/assets/${iconImg}`}
                  alt={`${iconImg}-icon`}
                  className="w-6 h-6 absolute right-[7px] top-[7px] cursor-pointer"
                  onClick={() => {
                    if (qrId === item["_id"]) return setQrId(null);
                    setQrId(item["_id"]);
                  }}
                />
              )}

              {/* Is used coupon */}
              {!isUsed && (
                <Popconfirm
                  title={t("confirmIsUsed")}
                  description="Are you sure to delete this task?"
                  onConfirm={(e: any) => confirm(e, item)}
                  onCancel={() => null}
                  okText={t(`button.confirm`)}
                  cancelText={t(`button.cancel`)}
                >
                  <p className="text-[#4269AB] cursor-pointer text-[12px] mt-1">
                    {t("confirmIsUsed")}
                  </p>
                </Popconfirm>
              )}

              {/* Used coupon */}
              {isUsed && (
                <p className="text-[#EA437A] cursor-pointer text-[12px] mt-1">
                  {t("used")}
                </p>
              )}

              {/* QR Code Icon */}
              {qrId === item["_id"] && (
                <div className="pt-5 pb-5 w-[100%] text-center">
                  <>
                    {couponType === "qr_code" && (
                      <QRCode
                        value={item.coupon_code}
                        style={{ margin: "auto" }}
                      />
                    )}
                    {couponType === "barcode" && (
                      <div className="flex justify-center px-6 py-3 w-full">
                        <Barcode value={item.coupon_code} />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="flex flex-col items-center">
        <button
          className="mt-5 py-2 mb-2 w-[150px]"
          onClick={() => history.push("/wallet")}
        >
          {t("button.returnToWallet")}
        </button>

        <button
          className="mt-1 py-2 mb-2 w-[150px] secondary"
          onClick={() => history.push("/coupons-home")}
        >
          {t("button.continueExploring")}
        </button>
      </div>
    </Container>
  );
};
