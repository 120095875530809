import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BottomModal, openNotificationWithIcon } from "src/components";
import i18n from "src/i18n";
import { history } from "src/stores";
import { CS } from "./cs";
import { useAuthStore } from "../../stores";

export const QuickIcons = (props: any) => {
  const [langOpen, setLangOpen] = useState(false);
  const [csOpen, setCsOpen] = useState(false);
  const { data, user } = props;
  const { t } = useTranslation();
  const { logout } = useAuthStore();

  const redirect = (page: string) => {
    if (page === "change-lang") return setLangOpen(true);
    if (page === "cs") return setCsOpen(true);
    history.push(page, { user });
  };

  const changeLang = (lang: string) => {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const renderSelectLang = () => {
    return (
      <div className="p-5 flex flex-col text-center">
        <p className="pb-5" onClick={() => changeLang("en")}>
          English
        </p>
        <p className="pb-5" onClick={() => changeLang("tc")}>
          繁體中文
        </p>
        <p onClick={() => changeLang("sc")}>简体中文</p>
      </div>
    );
  };

  return (
    <div className="mt-5 relative p-5 py-3 grid grid-cols-5 gap-2 bg-white drop-shadow-md">
      {data.map((item: any, i: any) => {
        return (
          <div
            className="flex flex-col text-center pt-1 cursor-pointer"
            key={i}
            onClick={
              item.page
                ? () => {
                    if (item.name === "logout") return logout();
                    redirect(item.page);
                  }
                : () =>
                  openNotificationWithIcon(
                    "info",
                    t("messages.pleaseWait"),
                    t("messages.comingSoon")
                  )
            }
          >
            {item.icons}
            <p className={`mt-2`}>{t(item.name)}</p>
          </div>
        );
      })}

      <BottomModal setOpen={setLangOpen} open={langOpen}>
        {renderSelectLang()}
      </BottomModal>

      <BottomModal setOpen={setCsOpen} open={csOpen}>
        <CS />
      </BottomModal>
    </div>
  );
};
