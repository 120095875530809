import React, { useEffect, useMemo, useState } from "react";
import { BottomModal, openWarningNotification } from "../../components";
import { useTranslation } from "react-i18next";
import { useAuthStore, useCardStore } from "src/stores";
import { formatDollar } from "src/utils";
import { Card } from "./card";
import { CardInfo } from "./card-info";
import { ByonNavigationBar } from "src/components/byon-navigation-bar";
import { OpenGcModal } from "./open-gc-modal";

// const BYON_YELLOW = "#FFCD38";

export const ByonServiceHome = (props: any) => {
  const { t } = useTranslation();
  const { getCardInfo } = useCardStore();
  const { access } = useAuthStore();
  const [login, setLogin] = useState<boolean>(false);
  const [card, setCard] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  const countryCode = params["country_code"];
  const phoneNum = params["phone_num"];

  useEffect(() => {
    if (login) return;
    if (!countryCode || !phoneNum) return;
    access({
      phoneNum,
      countryCode,
      otp: "KY5fpkD8szo^sF11q*t6DEeWoQlBfNeM",
    }).then((result) => {
      if (!result)
        return openWarningNotification(t("messages.failedToVerifyUser"));

      setLogin(true);
    });
  }, [login, countryCode, phoneNum, access, t]);

  useEffect(() => {
    if (!login) return;
    setLoading(true);
    getCardInfo(`?card_service=gc&service=at_33`).then((item) => {
      setLoading(false);
      if (!item || !item["card_number"]) return setCard(null);
      setCard(item);
    });
  }, [login, setCard, getCardInfo]);

  const balance = useMemo(() => {
    if (!card || !card["balance"]) return 0;
    const balance = parseFloat(card.balance) / 100;
    return formatDollar(balance);
  }, [card]);

  return (
    <div className="relative bg-[#020815] min-h-screen pb-[200px]">
      {/* BG gradient */}
      <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full z-0">
        <img
          src="/assets/byon_bg.png"
          className="w-full h-full object-fit"
          alt="bg"
        />
      </div>

      {/* Wave */}
      <div className="absolute bottom-0 left-0 w-full z-0">
        <img
          src="/assets/byon_wave.png"
          className="w-full object-fit"
          alt="wave"
        />
      </div>

      {/* Nav */}
      <div className="z-[3] relative">
        <ByonNavigationBar />
      </div>

      <div className="mt-10 px-[30px] z-[3] relative">
        {/* Card */}
        <Card loading={loading} card={card} balance={balance} />

        {/* Scan to pay - HIDE for now */}
        {/* {card && (
          <>
            <div>
              <button
                className={`mt-5 byon full !px-5 text-xs !py-3`}
                onClick={() =>
                  history.push(
                    `/byon-scan?country_code=${countryCode}&phone_num=${phoneNum}`,
                    {
                      cardNumber: card.card_number,
                    }
                  )
                }
              >
                {t("services.scanToPay")}
              </button>
            </div>
          </>
        )} */}
      </div>

      {/* Card Info */}
      {card && (
        <div className="px-[30px] mt-10 z-[3] relative">
          <h5 className="text-white text-[32px]">{t("services.cardInfo")}</h5>
          <CardInfo loading={loading} card={card} setOpen={setOpen} />
        </div>
      )}

      {/* Pop Up Modal */}
      <BottomModal open={open} setOpen={setOpen}>
        <OpenGcModal />
      </BottomModal>
    </div>
  );
};
