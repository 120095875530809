import { t } from "i18next";
import React from "react";

export const AvailableOutlets = (props: any) => {
  const { data = [] } = props;

  return (
    <div className="flex flex-col self-start">
      <div className="flex flex-row">
        <h5>{t("ticket.place")}</h5>
      </div>

      <button className="mt-1 py-1 mb-2 max-w-[100px]">{t("ticket.hk")}</button>

      {data.length > 0 &&
        data.map((item: any, i: any) => (
          <div className="pt-2 first:pt-5" key={i}>
            <p className="text-black">{item.name}</p>
            <p className="text-[9px] text-slate-400">{item.address}</p>
          </div>
        ))}
    </div>
  );
};
