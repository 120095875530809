import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { IsLogin } from "src/utils/token";

const AuthProvider =
  <P extends any>(Component: any): FC<P> =>
  (props) => {
    if (IsLogin()) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  };

export default AuthProvider;
