import { t } from "i18next";
import React, { useState } from "react";
import {
  BottomModal,
  TopUpSummary,
  LoadingIcon,
  openErrNotification,
} from "src/components";
import { useMemberStore, useTransactionStore } from "src/stores";

// Config
import { paypal } from "src/config";
import { AIR_WALLEX_OPTIONS } from "src/utils";
import { PaymeModal } from "./payme-modal";
import { AirWallexModal } from "./airwallex-modal";

import { history } from "src/stores";
import { OctopusModal } from "./octopus-modal";

const {
  PayPalScriptProvider,
  PayPalButtons,
} = require("@paypal/react-paypal-js");

export const ConfirmTopupModal = (props: any) => {
  const {
    balance,
    topupAtpAmount,
    selectedPayment,
    confirmOpen,
    setConfirmOpen,
  } = props;

  const [paymeOpen, setPaymeOpen] = useState(false);
  const [OctopusOpen, setOctopusOpen] = useState(false);
  const [airwallexOpen, setAirwallexOpen] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<{}>({});
  const [loading, setLoading] = useState(false);

  const { updateUserInfo } = useMemberStore();
  const { checkPaymentOrder, createPaymentOrder, updatePaymentOrder } =
    useTransactionStore();

  const approveOrder = async (id: any) => {
    try {
      const updatedDone = await updatePaymentOrder({
        orderId: id,
        status: "succeed",
      });
      // Update user AT Points
      const newAmount = parseFloat(balance) + parseFloat(topupAtpAmount);
      const debitedUser =
        updatedDone && (await updateUserInfo({ atPoints: String(newAmount) }));
      return debitedUser && history.push("/top-up-done");
    } catch (e) {
      return openErrNotification("messages.approveOrderErr");
    }
  };

  const approvePaypalOrder = async (data: any, actions: any) => {
    return actions.order.capture().then(async (details: any) => {
      await approveOrder(details["id"]);
    });
  };

  const cancelOrder = async (data: any, actions: any) => {
    await updatePaymentOrder({ orderId: data["orderID"], status: "cancelled" });
  };

  const createOrder = async (data: any, actions: any) => {
    const order = await actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "HKD",
            value: topupAtpAmount,
          },
        },
      ],
    });

    const payload = {
      orderId: order,
      amount: topupAtpAmount,
      currency: "HKD",
      method: selectedPayment,
    };
    await createPaymentOrder(payload);
    return order;
  };

  const rejectOrder = async (data: any, actions: any) => {
    await updatePaymentOrder({ orderId: data["orderID"], status: "rejected" });
  };

  const isAirWallex = () => AIR_WALLEX_OPTIONS.includes(selectedPayment);

  const startPayment = async (method: string) => {
    setConfirmOpen(false); //Close Confirm Modal
    if (loading) return;
    setLoading(true);
    let paymentMethod = selectedPayment;
    if (isAirWallex()) paymentMethod = "airwallex";

    const payload = {
      amount: topupAtpAmount,
      currency: "HKD",
      method: paymentMethod,
    };
    const data = await createPaymentOrder(payload);
    if (!data) return openErrNotification(t("messages.createPaymentFailed"));

    switch (method) {
      case "payme":
        setPaymeOpen(true);
        break;
      case "alipay": //Airwallex
      case "credit_card":
      case "wechat_pay":
        setAirwallexOpen(true);
        break;
      case "octopus":
        setOctopusOpen(true);
        break;
      default:
        break;
    }

    setPaymentDetails(data);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <BottomModal setOpen={setConfirmOpen} open={confirmOpen}>
        <div className="p-5 pt-3 text-center min-h-[400px] flex flex-col">
          <h5 className="text-center text-[12px] pb-[15px]">
            {t("confirmTopup")}
          </h5>
          <TopUpSummary
            selectedPayment={selectedPayment}
            topupAtpAmount={topupAtpAmount}
          />
        </div>

        <div className="px-5 fixed flex flex-col bottom-[30px] w-full">
          {/* Confirm Top Up Button x 4 */}
          <div>
            {selectedPayment === "paypal" && (
              <PayPalScriptProvider
                options={{ "client-id": paypal.clientId, currency: "HKD" }}
              >
                <PayPalButtons
                  fundingSource={"paypal"}
                  createOrder={(data: any, actions: any) =>
                    createOrder(data, actions)
                  }
                  onApprove={(data: any, actions: any) => {
                    approvePaypalOrder(data, actions);
                  }}
                  onCancel={(data: any, actions: any) => {
                    cancelOrder(data, actions);
                  }}
                  onError={(data: any, actions: any) => {
                    rejectOrder(data, actions);
                  }}
                />
              </PayPalScriptProvider>
            )}
            {selectedPayment === "payme" && (
              <button
                className="primary full payme flex flex-row justify-center items-center !rounded-full"
                onClick={() => startPayment(selectedPayment)}
              >
                {loading ? (
                  <div className="py-3">
                    <LoadingIcon size={15} color="#fff" />
                  </div>
                ) : (
                  <img
                    src="/assets/payme-logo-white@2x.png"
                    alt="payme-logo"
                    className="h-[45px] object-fit mx-auto"
                  />
                )}
              </button>
            )}

            {selectedPayment === "octopus" && (
              <button
                className="full bg-gradient-to-r from-[#fdad1d] to-[#f65a17]"
                onClick={() => startPayment(selectedPayment)}
              >
                {loading ? (
                  <div className="py-3">
                    <LoadingIcon size={15} color="#fff" />
                  </div>
                ) : (
                  <p className="py-3 text-white">{t("button.octopus")}</p>
                )}
              </button>
            )}

            {isAirWallex() && (
              <button
                className="primary full flex flex-row justify-center items-center"
                onClick={() => startPayment(selectedPayment)}
              >
                <p className="py-3 text-white">{t("confirmTopup")}</p>
              </button>
            )}
          </div>

          {/* Go back button */}
          <button
            className="primary full mt-4 py-3 secondary"
            onClick={() => setConfirmOpen(false)}
          >
            {t("backUp")}
          </button>
        </div>
      </BottomModal>

      {/* PayMe Modal */}
      {selectedPayment === "payme" && (
        <PaymeModal
          open={paymeOpen}
          setOpen={setPaymeOpen}
          paymentDetails={paymentDetails}
          checkPaymentOrder={checkPaymentOrder}
          updatePaymentOrder={updatePaymentOrder}
          approveOrder={approveOrder}
          selectedPayment={selectedPayment}
        />
      )}

      {/* Octopus Modal */}
      {selectedPayment === "octopus" && (
        <OctopusModal
          open={OctopusOpen}
          setOpen={setOctopusOpen}
          paymentDetails={paymentDetails}
          checkPaymentOrder={checkPaymentOrder}
          updatePaymentOrder={updatePaymentOrder}
          approveOrder={approveOrder}
          selectedPayment={selectedPayment}
        />
      )}

      {/* AirWallex Modal */}
      {isAirWallex() && (
        <AirWallexModal
          open={airwallexOpen}
          setOpen={setAirwallexOpen}
          paymentDetails={paymentDetails}
          checkPaymentOrder={checkPaymentOrder}
          updatePaymentOrder={updatePaymentOrder}
          approveOrder={approveOrder}
          selectedPayment={selectedPayment}
        />
      )}
    </React.Fragment>
  );
};
