import { t } from "i18next";
import React from "react";

const labelClass = "block text-[10px] font-bold text-[#707070]";
const inputClass =
  "mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

const topUpAmountOptions = [
  { amount: "10", currency: "HKD" },
  { amount: "100", currency: "HKD" },
  { amount: "500", currency: "HKD" },
  { amount: "1000", currency: "HKD" },
];

export const AtPointBalance = (props: any) => {
  const { balance, topupAtpAmount, setTopupAtpAmount } = props;

  const handleInputChange = (e: any) => {
    setTopupAtpAmount(e.target.value);
  };

  const handleOption = (amount: any) => {
    setTopupAtpAmount(amount);
  };

  return (
    <div className="bg-white p-[20px] drop-shadow-md">
      <h5>{t("myPoints")}</h5>
      <div className="py-[10px] flex flex-row">
        <div className="flex flex-row items-center">
          <div className="w-[25px] mr-[10px]">
            <img src={"/assets/AT_logo.png"} alt={"at-logo"} />
          </div>
          <div>
            <span className="text-[18px] text-black mr-[5px]">
              {balance ? balance : 0}
            </span>
            <span className="text-[12px] text-black">AT</span>
          </div>
        </div>
      </div>

      {/* Top Up Amount */}
      <label className="block mt-[10px]">
        <span className={labelClass}>{t("topupAmount")}</span>
        <input
          type="number"
          name="amount"
          className={inputClass}
          placeholder="0"
          value={topupAtpAmount}
          onChange={handleInputChange}
        />
      </label>

      {/* Select Top Up Amount */}
      <div className="flex flex-row whitespace-nowrap scrollbar-hide overflow-x-auto lg:overflow-x-scroll scroll-px-5 snap-x pt-[10px]">
        {topUpAmountOptions.map((option: any, i: any) => {
          return (
            <div key={i} className="max-w-lg">
              <button
                className={`${
                  topupAtpAmount === option.amount ? "primary" : "secondary"
                } flex overflow-hidden mr-2`}
                onClick={() => handleOption(option.amount)}
              >
                <p
                  className={`flex flex-row py-[5px] text-[10px] ${
                    topupAtpAmount === option.amount
                      ? "text-white"
                      : "text-at-pink"
                  }`}
                >
                  <span className="pr-1">{option.amount}</span>
                  <span>{option.currency}</span>
                </p>
              </button>
            </div>
          );
        })}
      </div>

      {/* Exchange Rate */}
      <h5 className="text-[#707070] text-[10px] flex justify-center w-full pt-[15px]">
        {t("exchangeRate")}
      </h5>
    </div>
  );
};
