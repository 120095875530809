import { t } from "i18next";
import React, { useMemo } from "react";
import { history } from "../../stores";
import moment from "moment";
import {
  openNotificationWithIcon,
  openWarningNotification,
} from "../notification";
import i18n from "src/i18n";

export const SmallTicket = (props: any) => {
  const language = i18n.language;
  const {
    couponTitle,
    couponTitleEn,
    couponIcon = "/assets/AT_logo.png",
    amount,
    atPoints,
    expirationDate,
    from,
    couponSupplies,
    stocks,
    allowVisit = true,
  } = props;

  const redirection = () => {
    if (!allowVisit) {
      if (from === "nft-exclusive-flash-sale") {
        return openWarningNotification(t("messages.nft_or_tier_required"));
      }
      return openWarningNotification(t("messages.obsidianLevelRequired"));
    }

    if (from === "myCoupon") {
      if (count === 0)
        return openNotificationWithIcon(
          "info",
          t("messages.warning"),
          t("messages.noCoupon")
        );
    }

    if (stocks === 0) {
      return openNotificationWithIcon(
        "info",
        t("messages.warning"),
        t("messages.couponSoldout")
      );
    }
    return history.push("/coupon-detail", { coupon: props, from });
  };

  const count = useMemo(() => {
    if (!couponSupplies) return 0;

    const unUsedCoupons = couponSupplies.filter((item: any) => !item.is_used);
    return unUsedCoupons.length;
  }, [couponSupplies]);

  const title = useMemo(() => {
    if (language === "en") return couponTitleEn || couponTitle;
    return couponTitle;
  }, [language, couponTitle, couponTitleEn]);

  return (
    <div
      className="relative w-full bg-white flex flex-row px-5 py-3 items-center rounded-2xl border-[1px] cursor-pointer"
      onClick={() => redirection()}
    >
      <div className="w-14 h-14 rounded-full overflow-hidden">
        <img
          src={couponIcon}
          alt={couponTitle}
          className=" w-full h-full object-contain"
        />
      </div>

      <div className="mx-5 h-10 block bg-slate-200 w-[1px]" />

      <div className="max-w-[200px]">
        <p className="truncate overflow-hidden max-h-[18px]">{title}</p>
        <div className="flex flex-row items-end pb-3">
          {amount && (
            <p className="text-black text-xl tracking-tight">${amount}</p>
          )}
          {amount && atPoints && (
            <p className="px-2 relative top-[-3px]">{t("or")}</p>
          )}
          {atPoints && (
            <p className="text-black text-xl tracking-tight rainbow">
              {atPoints} <span className="text-sm text-black">AT</span>
            </p>
          )}
        </div>

        {expirationDate && (
          <p className="text-[9px] text-slate-400">
            {t("expiration")}: {moment(expirationDate).format("Do MMMM YYYY")}
          </p>
        )}
      </div>

      {/* Left circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[50%] left-[-10px] mt-[-10px] overflow-hidden" />

      {/* Right circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[50%] right-[-10px] mt-[-10px] overflow-hidden" />

      {/* Supply Counts */}
      {couponSupplies && (
        <div className="flex flex-1 items-center justify-center w-5 h-5 bg-at-pink rounded-full absolute top-[-5px] right-[-5px]">
          <p className="text-white text-bold mt-[1px]">{count}</p>
        </div>
      )}

      {stocks === 0 && (
        <div className="flex flex-1 items-center justify-center absolute top-[-5px] right-[-5px]">
          <button className="small primary">{t("button.soldOut")}</button>
        </div>
      )}
    </div>
  );
};
