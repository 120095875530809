require("dotenv").config();

export const apiUrl = process.env.REACT_APP_API_URL;
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export const paypal: any = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
};

export const BYON_URL = process.env.REACT_APP_BYON_URL;
export const MINTING_URL = process.env.REACT_APP_MINTING_URL;
export const AIRWALLET_URL = process.env.REACT_APP_AIRWALLEX_URL;
export const PLATFORM = process.env.REACT_APP_PLATFORM || "dev";
