import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { LoadingIcon, SmallTicket } from "src/components";
import { useMemberStore } from "../../stores";

export const Coupons = (props: any) => {
  const { from, coupons, loading = false } = props;

  const { getUserMembershipInfo } = useMemberStore();
  const [allowVisit, setAllowVisit] = useState(false);

  useEffect(() => {
    getUserMembershipInfo().then((result) => {
      if (result && result.tier && result.tier === "OBSIDIAN")
        setAllowVisit(true);
    });
  }, [getUserMembershipInfo]);

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  const hasCoupon = useMemo(() => {
    return coupons && coupons.length > 0;
  }, [coupons]);

  return (
    <div className="mt-1 bg-transparent p-5 overflow-hidden overflow-x-scroll scroll-px-5 snap-x ">
      <div className="">
        {isLoading && (
          <div className="flex justify-center pt-5">
            <LoadingIcon />
          </div>
        )}

        {!hasCoupon && !isLoading && (
          <div className="flex justify-center pt-5">
            <p>{t("noCouponFound")}</p>
          </div>
        )}

        {hasCoupon &&
          coupons.map((item: any, i: number) => {
            return (
              <div key={i} className="relative pb-5 last:pb-0">
                <SmallTicket
                  id={item["_id"]}
                  couponId={item.coupon_id}
                  couponCode={item.coupon_code}
                  couponTitle={item["name"]}
                  couponTitleEn={item["name_en"]}
                  couponIcon={item["logo"]}
                  amount={item.amount}
                  atPoints={item.at_points}
                  expirationDate={item.expiry_date}
                  availableOutlets={item.available_outlets}
                  tnc={item.tnc}
                  instructions={item.how_to_use}
                  couponSupplies={item.coupon_supplies}
                  from={from}
                  stocks={item.available_stocks}
                  // for the highest level of member tier
                  allowVisit={allowVisit}
                  redemption_type={item.redemption_type}
                  type={item.type}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
