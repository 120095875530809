import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { useCouponStore } from "src/stores";
import { Coupons } from "../coupons-home/coupons";

export const WalletCoupons = (props: any) => {
  const { t } = useTranslation();
  const { user } = props;
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getUserCouponsV2 } = useCouponStore();

  useEffect(() => {
    getUserCouponsV2().then((result: any) => {
      setLoading(false);
      if (!result) return;
      setCoupons(result);
    });
  }, [getUserCouponsV2, setCoupons, setLoading]);

  return (
    <Container toPage={"/wallet"} title={t("myCoupons")}>
      <Coupons
        from="myCoupon"
        loading={loading}
        coupons={coupons}
        user={user}
      />
    </Container>
  );
};
