import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MovieList } from "./movie-list";
import { Container } from "src/components";
import { useCinemaStore } from "src/stores";
import { LoadingIcon } from "src/components";

export const MovieIndex = (props: any) => {
  const { location } = props;
  const { getCinemaMovieList } = useCinemaStore();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCinemaMovieList({ filmCategory: "all" }).then((result) => {
      setLoading(false);
      if (result) return setMovies(result.film_list);
    });
  }, [setLoading, getCinemaMovieList, setMovies]);

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  const hasMovie = useMemo(() => {
    return movies && movies.length > 0;
  }, [movies]);

  return (
    <Container toPage={"/"} title={t("backUp")}>
      <div className="p-5">
        {isLoading && (
          <div className="flex justify-center flex-1 py-5">
            <LoadingIcon />
          </div>
        )}

        {!hasMovie && !isLoading && (
          <div className="flex justify-center flex-1 py-5">
            <p>{t("noMovieFound")}</p>
          </div>
        )}

        <div className="grid grid-cols-2 gap-2">
          <MovieList
            hasMovie={hasMovie}
            movies={movies}
            loading={loading}
            location={location}
          />
        </div>
      </div>
    </Container>
  );
};

export * from "./movie-detail";
