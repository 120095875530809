import { GetToken, SetToken } from "src/utils/token";
import { generalError } from ".";
import { history } from ".";

export const errorHandling = (e: any) => {
  if (!e) return generalError;

  const msg = e.response.data.message || e.response.data.error || null;
  switch (msg) {
    case "jwt_unauthorized":
      localStorage.removeItem("at-token");
      history.push("/login");
      return;
    case "failed_to_user_inactive":
      return { success: false, error: "failedToUserInactive" };
    case "failed_to_verify_user":
      return { success: false, error: "failedToVerifyUser" };
    case "otp_incorrect":
      return { success: false, error: "otpIncorrect" };
    case "transaction_not_found":
      return { success: false, error: "noTransaction_Found" };
    default:
      return generalError;
  }
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return SetToken(token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  };

  return config;
};
